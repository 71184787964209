import {Pressable, Linking, View} from 'react-native';
import React from 'react';
import {imageFromAPI} from '../../../utils';
import NumbersCarouselItem from '../NumbersCarouselItem';
import RacingCarouselItem from '../RacingCarouselItem';
import Image from '../../../components/global/FnsImage/';
import {
  CarouselProvider,
  Slider,
  Slide,
  DotGroup,
  Dot,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import PartnerOffer from '../PartnerOffer';

type Props = {
  carousel: any;
  containerHeight: number;
  containerWidth: number;
  screen: string;
  isPartnerOffer?: boolean;
};

const WebCarousel = ({
  carousel,
  containerHeight,
  containerWidth,
  screen,
  isPartnerOffer,
}: Props) => {
  const isDesktop = screen === 'desktop';
  return (
    <CarouselProvider
      naturalSlideWidth={containerWidth}
      naturalSlideHeight={containerHeight}
      totalSlides={carousel.length}
      interval={5000}
      isPlaying
      infinite>
      <Slider>
        {carousel.map((item, index) => {
          return (
            <Slide key={`carousel${index}`} index={index}>
              {isPartnerOffer ? (
                <View style={{height: '100%'}}>
                  {item?.map(offer => {
                    return (
                      <View
                        key={offer.id}
                        style={{
                          marginVertical: 5,

                          alignItems: 'center',
                        }}>
                        <PartnerOffer
                          maxWidth={containerWidth}
                          item={offer}
                          parent="popup"
                        />
                      </View>
                    );
                  })}
                </View>
              ) : (
                <Pressable
                  key={item.id}
                  disabled={item?.product_type === 'Product'}
                  onPress={() =>
                    item?.website && Linking.openURL(item?.website)
                  }
                  style={{
                    height: '100%',
                    width: '100%',

                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 5,
                    overflow: 'hidden',
                    // marginHorizontal: 5,
                    margin: 'auto',
                  }}>
                  <Image
                    fetchpriority="high"
                    loading="eager"
                    style={{height: containerHeight, width: '100%'}}
                    imageURL={imageFromAPI(
                      screen && screen === 'phone'
                        ? item.background_image_url_mobile
                        : item?.background_image_url,
                      {
                        width: isDesktop ? 500 : 200,
                        height: isDesktop ? 167 : 100,
                      },
                    )}
                  />

                  {item?.event?.type === 'numbers' ? (
                    <NumbersCarouselItem screenType={screen} item={item} />
                  ) : null}

                  {item?.event?.type === 'racing' ? (
                    <RacingCarouselItem screenType={screen} item={item} />
                  ) : null}
                </Pressable>
              )}
            </Slide>
          );
        })}
      </Slider>
      <View
        style={{
          alignItems: 'center',
          marginTop: 5,
          width: '100%',
          position: 'absolute',
          top: containerHeight - 25,
        }}>
        <DotGroup
          renderDots={({currentSlide}) =>
            carousel.map((_, index) => {
              return (
                <Dot
                  style={{
                    marginRight: 5,
                    height: 15,
                    width: 15,
                    borderRadius: 25,
                    borderWidth: 0,
                    backgroundColor: currentSlide == index ? 'white' : 'gray',
                  }}
                  slide={index}
                  key={`dot-${index}`}
                />
              );
            })
          }
        />
      </View>
    </CarouselProvider>
  );
};

export default WebCarousel;
