/**
 * Currently, this component will only render a linear gradient for web.
 * When we ge to the App phase, we can add `react-native-linear-gradient`, and add that to the return method here
 *
 * Web implementation:
 * - We have webGradientMapping, where we can set the percent, degrees, and number of colours for specfici circumstances
 * - If a webGradientType is not included, it will default to an even split of the 'n' colours
 */

import React from 'react'
import {LinearGradientProps} from '../../@types/components' // eslint-disable-line @typescript-eslint/no-unused-vars

import Gradient from '../global/Gradients/'

const LinearGradient: React.FC<LinearGradientProps> = ({colours, style, children, webGradientType, onLayout}) => {

  return (
      <Gradient style={style} onLayout={onLayout} webGradientType={webGradientType} colours={colours}>
        {children}
      </Gradient>
  )
}

export default LinearGradient
