/**
 *
 * Context is a feature that comes out of the box with React Native: https://reactjs.org/docs/context.html
 *
 * We use it throughout the app to keep track of the navigation values we receive from the API.
 * We currently store the following navigation values:
 *  - products: all links, and icons that will be displayed across the top of the site
 *  - primary: all links that are fixed across all pages
 *  - footer: all lonks that are fixed to the bottom across all pages
 *  - secondary: will be an object, holding the keys of all our secondary nav objects, as set up in the CMS. These keys will be added, as we navigate to the specific stack
 *
 * The App will be wrapped in AppProvider, giving all children access to the context through the variable AppContext
 *
 */

import {createContext} from 'react'

import {AllContextValues} from '../@types/context' // eslint-disable-line @typescript-eslint/no-unused-vars

const AppContext = createContext({} as AllContextValues)

export const AppProvider = AppContext.Provider

export default AppContext
