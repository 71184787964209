import React, {useContext} from 'react';
import {ScrollView} from 'react-native-gesture-handler';

import ProductLogoItem from './ProductLogoItem';

import AppContext from '../../context/AppContext';
import {allProducts} from '../../products';

import GlobalStyles from '../../assets/styles';

import {ProductLogoSliderProps} from '../../@types/components'; // eslint-disable-line @typescript-eslint/no-unused-vars
import useNexToGo from '../../hooks/useNexToGo';

///////////// Functional Component
const ProductLogoSlider: React.FC<ProductLogoSliderProps> = ({
  onPress,
  // nextToGo,
  style,
  blendToSubNav,
  selectedIcon,
  bottomTriangle,
  // updateNextToGo,
}) => {
  const context = useContext(AppContext);
  const navItems = context.navItems;

  const {nextToGo, getAndSetNextTogo} = useNexToGo();

  return (
    <ScrollView
      showsHorizontalScrollIndicator={false}
      horizontal={true}
      contentContainerStyle={[
        GlobalStyles.row,
        GlobalStyles.paddingLeftSM,
        style,
      ]}>
      {navItems.products.pages?.length &&
        navItems.products.pages.map(product => {
          if (product.hide_from_main_nav) {
            return;
          }
          const thisProduct = allProducts.find(p => p.path === product.path);

          const nextToGoItem = nextToGo?.find(
            ntg => ntg?.sub_type === thisProduct?.code,
          );

          return (
            <ProductLogoItem
              key={`ProductLogoItem${product.path}`}
              product={product}
              selectedIcon={selectedIcon}
              onPress={onPress}
              bottomTriangle={
                bottomTriangle !== undefined ? bottomTriangle : true
              }
              blendToSubNav={blendToSubNav}
              updateNextToGo={nextToGoItem ? getAndSetNextTogo : undefined}
              time={nextToGoItem?.time}
            />
          );
        })}

      {/* {nextToGo ? (
        nextToGo.map((item) => {
          const productPath = allProducts.find(p => p.code === item.sub_type)?.path
          if (!productPath) {return}

          const product = navItems.products.pages.find((p) => p?.path === productPath)
          if (!product) { return }

          return (
            <ProductLogoItem
              key={`ProductLogoItem${item.sub_type}`}
              product={product}
              selectedIcon={selectedIcon}
              onPress={onPress}
              time={item.time}
              updateNextToGo={updateNextToGo}
            />
          )
        })
      ) : (
        navItems.products.pages?.length && navItems.products.pages.map((product) => {
          if (product.hide_from_main_nav) {return}

          return (
            <ProductLogoItem
              key={`ProductLogoItem${product.path}`}
              product={product}
              selectedIcon={selectedIcon}
              onPress={onPress}
              bottomTriangle={bottomTriangle !== undefined ? bottomTriangle : true}
              blendToSubNav={blendToSubNav}
            />
          )
      }))} */}
    </ScrollView>
  );
};

export default ProductLogoSlider;
