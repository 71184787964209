import React from 'react';
import {
  Image,
  StyleSheet,
  View,
  useWindowDimensions,
  ActivityIndicator,
} from 'react-native';

import Text from '../text/FnsText';

// import Layout from '../../assets/Layout'
import GlobalStyles from '../../assets/styles';

import {CardProps} from '../../@types/components'; // eslint-disable-line @typescript-eslint/no-unused-vars
import Colours from '../../assets/Colours';
// import AppContext from '../../context/AppContext'

// images
// const BackgroundHot = '/hot_background.png'
import BackgroundCold from '../../Images/cold_background.png';
import BackgroundHot from '../../Images/hot_background.png';
import IconCold from '../../Images/cold_icon.png';
import IconHot from '../../Images/hot_icon.png';
// const IconCold = '/cold_icon.png'
// const IconHot = '/hot_icon.png'

const Card: React.FC<CardProps> = ({
  BodyContent,
  bodyStyle,

  noHeader,
  border,
  headerText,
  headerSubtext,
  headerSubtextSize,
  headerTextStyle,
  headerTextSize,
  headerStyle,

  HeaderLeftComponent,
  headerLeftComponentStyle,

  HeaderRightComponent,
  headerRightComponentStyle,

  containerStyle,
  SVGBackground,
  SVGIcon,
  loading,
  loadingContainerHeight = 150,
}) => {
  const {width} = useWindowDimensions();
  // const context = useContext(AppContext)

  return (
    <View
      style={[
        GlobalStyles.hiddenOverflow,
        GlobalStyles.transparentBG,
        GlobalStyles.borderRadiusSM,
        border ? GlobalStyles.borderWidthNarrow : undefined,
        GlobalStyles.borderGray,
        containerStyle,
      ]}>
      {noHeader || (
        <View style={[GlobalStyles.paddingSM, GlobalStyles.row, headerStyle]}>
          <>
            {SVGBackground ? (
              <>
                <Image
                  resizeMode="cover"
                  source={
                    SVGBackground === 'BackgroundCold'
                      ? (BackgroundCold as any)
                      : SVGBackground === 'BackgroundHot'
                      ? (BackgroundHot as any)
                      : null
                  }
                  style={styles.svgBGWrapper}
                />
                <Image
                  resizeMode="contain"
                  source={
                    SVGIcon === 'IconCold'
                      ? (IconCold as any)
                      : SVGIcon === 'IconHot'
                      ? (IconHot as any)
                      : null
                  }
                  style={width > 320 ? styles.icon : styles.iconSmallMobiles}
                />
              </>
            ) : null}
            {HeaderLeftComponent && (
              <View
                style={[
                  GlobalStyles.paddingRightSM,
                  GlobalStyles.iconSizeMedium,
                  GlobalStyles.justifyCenter,
                  headerLeftComponentStyle,
                ]}>
                {HeaderLeftComponent}
              </View>
            )}

            <View style={[GlobalStyles.flexGrow, GlobalStyles.justifyCenter]}>
              <Text
                size={headerTextSize || 'Medium'}
                style={[
                  GlobalStyles.whiteText,
                  GlobalStyles.uppercase,
                  headerTextStyle,
                ]}
                decoration={'Bold'}>
                {headerText}
              </Text>
              <Text
                style={[GlobalStyles.whiteText, headerTextStyle]}
                size={headerSubtextSize || 'Small'}>
                {headerSubtext}
              </Text>
            </View>

            {HeaderRightComponent && (
              <View
                style={[
                  GlobalStyles.iconSizeMedium,
                  GlobalStyles.justifyCenter,
                  headerRightComponentStyle,
                ]}>
                {HeaderRightComponent}
              </View>
            )}
          </>
        </View>
      )}

      <View style={[GlobalStyles.paddingSM, GlobalStyles.bodyBG, bodyStyle]}>
        {loading ? (
          <View
            style={{
              minHeight: loadingContainerHeight,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <ActivityIndicator size="large" />
          </View>
        ) : (
          BodyContent
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  svgBGWrapper: {
    position: 'absolute',
    top: '-100%',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: Colours.background,
  },
  icon: {
    position: 'absolute',
    top: 5,
    right: 5,
    zIndex: 1,
    height: 40,
    width: 32,
  },
  iconSmallMobiles: {
    position: 'absolute',
    top: 5,
    right: 3,
    zIndex: 1,
    height: '1.8rem',
    width: '2rem',
  },
});

export default Card;
