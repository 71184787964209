/**
 * Customised Text Component
 *
 * Receives:
 * - decoration:
 *  - Light
 *  - Bold
 *  - Italic
 *   - (default): Fonts.family
 * - size:
 *   - ExtraSmall
 *   - Small
 *   - SmallMedium
 *   - Medium
 *   - Large
 *   - ExtraLarge
 *   - (default): Fonts.sizeBody
 * - style
 * - children:
 *   - Children to render
 */

import React from 'react'
import {Text, StyleProp, TextStyle} from 'react-native' // eslint-disable-line @typescript-eslint/no-unused-vars

import Fonts from '../../assets/Fonts'

import {FnsTextProps} from '../../@types/components' // eslint-disable-line @typescript-eslint/no-unused-vars

const fontSizeMapping = {
  XXSmall: Fonts.sizeMini,
  ExtraSmall: Fonts.sizeCaption,
  Small: Fonts.sizeFootnote,
  SmallMedium: Fonts.sizeSubhead,
  MediumSmall: Fonts.sizeCallout,
  Medium: Fonts.sizeBody,
  MediumLarge: Fonts.sizeTitle5,
  Large: Fonts.sizeTitle4,
  ExtraLarge: Fonts.sizeTitle2,
}
const fontFamilyMapping = {
  Light: Fonts.familyLight,
  Bold: Fonts.familyBold,
  SemiBold: Fonts.familySemiBold,
  Italic: Fonts.familyItalic,
  Futura: Fonts.Futura,
}

const FnsText: React.FC<FnsTextProps> = ({
  decoration,
  size,
  children,
  style,
  numberOfLines,
  onPress,
}) => {
  const fontFamily = decoration ? fontFamilyMapping[decoration] : Fonts.family
  const fontStyle = {
    fontFamily: fontFamily,
    fontSize: size ? fontSizeMapping[size] : Fonts.sizeBody,
  } as StyleProp<TextStyle>

  return (
    <Text onPress={onPress} numberOfLines={numberOfLines} style={[fontStyle, style]}>
      {children}
    </Text>
  )
}

export default FnsText
