import {useFocusEffect} from '@react-navigation/native';
import React, {FC, Fragment, useEffect, useRef} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {ViewStyle} from 'react-native'; // eslint-disable-line @typescript-eslint/no-unused-vars

type AdProps = {
  placementId: string;
  hybridBanner?: boolean;
  richMedia?: boolean;
  style?: ViewStyle;
};

const Ad: FC<AdProps> = ({placementId, hybridBanner, richMedia, style}) => {
  const adRef = useRef<HTMLDivElement>(null);

  useFocusEffect(
    React.useCallback(() => {
      if (global.isDrawerOpen) return;
      if (window.top) {
        window.top.__vm_add = window.top.__vm_add || [];
        window.top.__vm_add.push(adRef.current);
        console.log('Venatus : vm_add : ', adRef.current);
      }

      const localRef = adRef.current;

      return () => {
        if (window.top) {
          if (localRef?.getAttribute('ad-type') === 'rich-media') {
            window.__vm_remove_category = window.__vm_remove_category || [];
            window.__vm_remove_category.push('richmedia_all');

            console.log('Venatus : vm_remove_category ', 'richmedia_all');
          } else {
            window.top.__vm_remove = window.top.__vm_remove || [];
            window.top.__vm_remove.push(localRef);

            console.log('Venatus : vm_remove : ', localRef);
          }
        }
      };
    }, []),
  );

  return (
    <Fragment>
      {richMedia ? (
        <div
          ref={adRef}
          className="vm-placement"
          data-id={placementId}
          ad-type="rich-media"
          style={{display: 'none'}}></div>
      ) : hybridBanner ? (
        <div
          style={style}
          ref={adRef}
          className="vm-placement"
          data-id={placementId}
          ad-type="hybrid-banner"
          data-display-type="hybrid-banner"></div>
      ) : (
        <div
          style={style}
          ref={adRef}
          className="vm-placement"
          data-id={placementId}
          ad-type="desktop-ad"></div>
      )}
    </Fragment>
  );
};
export default Ad;
