import {Image, Pressable, Linking, View} from 'react-native';
import React, {useContext} from 'react';
import GlobalStyles from '../../assets/styles';

// Images
import Facebook from '../../Images/Facebook.png';
// import Twitter from '../../Images/Twitter.png';
import Instagram from '../../Images/Instagram.png';
import XLogo from '../../Images/logo-white.png';
import OffAir from '../../Images/offAir3.png';
import {useNavigation} from '@react-navigation/native';
import AppContext from '../../context/AppContext';

const Socials = ({size = 'medium', onFooter = false}) => {
  const navigation = useNavigation();
  const {navItems, platform, setCurrentStack} = useContext(AppContext);
  const desktop = platform === 'desktop';

  const offAirLink = navItems['secondary/49s'].pages.find((item: any) =>
    item.title.includes('Off Air'),
  );

  const hideOffAirLink = offAirLink?.hide_from_main_nav;

  const sizeMap = {
    small: [{width: 20, height: 20}],
    medium: [GlobalStyles.fullWidth, GlobalStyles.height75pct],
  };

  const styleMap = {
    small: [
      GlobalStyles.iconSizeSmall,
      GlobalStyles.justifyCenter,
      GlobalStyles.alignCenter,
      GlobalStyles.twitterBlueBG,
      GlobalStyles.marginRightSM,
      GlobalStyles.borderRadiusCircle,
      GlobalStyles.marginXXS,
      {overflow: 'hidden'},
    ],
    medium: [
      GlobalStyles.iconSizeSmallMedium,
      GlobalStyles.justifyCenter,
      GlobalStyles.alignCenter,
      GlobalStyles.twitterBlueBG,
      GlobalStyles.marginRightMS,
      GlobalStyles.borderRadiusCircle,
      GlobalStyles.iconSizeSmallMedium,
      GlobalStyles.marginXXS,
      {overflow: 'hidden'},
    ],
  };

  const renderImage = (source: any) => (
    <Image source={source} resizeMode={'contain'} style={sizeMap[size]} />
  );
  const socials = [
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/49sdraw/',
      icon: renderImage(Facebook),
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/49sdraw',
      icon: renderImage(Instagram),
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/49sdraw',
      icon: renderImage(XLogo),
    },
    // {
    //   name: 'Youtube',
    //   url: 'https://www.youtube.com/channel/UCX29J71D_pIlscyg_fkVSNw',
    //   icon: renderImage(Youtube),
    // },
  ];

  return (
    <>
      <View style={{flexDirection: 'row'}}>
        {socials.map(social => (
          <Pressable
            key={social.name}
            onPress={() => Linking.openURL(social.url)}
            style={styleMap[size]}>
            {/* <View style={GlobalStyles.height75pct}> */}
            {social.icon}
            {/* </View> */}
          </Pressable>
        ))}
      </View>
      {!hideOffAirLink && (
        <View
          style={{
            height: 50,
            width: '100%',
            alignItems: desktop && onFooter ? 'flex-start' : 'center',
            justifyContent: 'center',
          }}>
          <Pressable
            style={{
              width: '90%',
              height: '100%',
              marginTop: 10,
              alignItems: 'center',

              maxWidth: 125,
            }}
            onPress={() => {
              setCurrentStack('49s');
              navigation.navigate(offAirLink?.title);
            }}>
            <Image
              source={OffAir}
              style={{
                height: '65%',
                width: '90%',
                padding: 10,
                borderRadius: 10,
              }}
            />
          </Pressable>
        </View>
      )}
    </>
  );
};

export default Socials;
