/**
 * Custom Activity Indicator to have more control over the look and feel for it.
 * Currently, Animated is not supported through react-native-web.
 * We have react-native-reanimated installed, so will be able to hook it up for both web and native, however was not the most pressing thing to do currently
 * TODO: Use react-native-animated to animate a loading indicator on web
 */

import React from 'react'
import {Animated, Easing, ActivityIndicator as WebActivityIndicator, Platform, Image} from 'react-native'

import Layout from '../../assets/Layout'

import {ActivityIndicatorProps, ActivityIndicatorSize} from '../../@types/components' // eslint-disable-line @typescript-eslint/no-unused-vars

const Loading = '/Loading.svg'

const ActivityIndicator: React.FC<ActivityIndicatorProps> = ({size = 'regular'}: ActivityIndicatorProps) => {
  if (Platform.OS === 'web') {
    return <WebActivityIndicator />
  }

  const rotateValue = new Animated.Value(0)

  Animated.loop(Animated.timing(rotateValue, {
    toValue: 1,
    duration: 500,
    easing: Easing.linear,
    useNativeDriver: true,
  })).start()
  const rotate = rotateValue.interpolate({
    inputRange: [0, 1, 2],
    outputRange: ['0deg', '360deg', '180deg'],
  })

  const attrs = {} as ActivityIndicatorSize
  switch (size) {
    case 'extraLarge':
      attrs.width = Layout.iconSizeExtraExtraLarge
      attrs.height = Layout.iconSizeExtraExtraLarge
      break
    case 'large':
      attrs.width = Layout.iconSizeMediumLarge
      attrs.height = Layout.iconSizeMediumLarge
      break
    case 'regular':
      attrs.width = Layout.iconSizeMedium
      attrs.height = Layout.iconSizeMedium
  }

  return (
    <Animated.View style={{ transform: [{ rotate: rotate }] }}>
      <Image
        source={{uri: Loading as any}}
        style={attrs}
      />
      {/* <IconLoading fill={color} {...attrs} /> */}
    </Animated.View>
  )
}

export default ActivityIndicator
