import React, { useContext } from 'react'
import {StyleSheet, View} from 'react-native'

import AppContext from '../../../context/AppContext'
import GlobalStyles from '../../../assets/styles'

import {BadgeProps} from '../../../@types/components' // eslint-disable-line @typescript-eslint/no-unused-vars

const Badge: React.FC<BadgeProps> = ({
  containerStyle,
  isActive,
}) => {
  const context = useContext(AppContext)
  const desktop = context.platform === 'desktop'
  const styles = desktop ? desktopStyle : mobileStyle

  return (
    <View style={StyleSheet.flatten([containerStyle && containerStyle])}>
      <View
        style={[
          styles.badge,
          GlobalStyles.alignSelfCenter,
          GlobalStyles.alignCenter,
          GlobalStyles.justifyCenter,
          isActive ? GlobalStyles.darkGrayBG : GlobalStyles.bodyBG,
        ]}
      />
    </View>
  );
};

const size = 15;
const desktopStyle = {
  badge: [{
    minWidth: size,
    height: size,
    borderRadius: size / 2,
  }],
}

const miniSize = 10;
const mobileStyle = {
  badge: [{
    minWidth: miniSize,
    height: miniSize,
    borderRadius: miniSize / 2,
  }],
}

export default Badge;
