// import {View, StyleSheet, Pressable, Linking} from 'react-native';
// import React, {useEffect, useState} from 'react';
// import APIImage from '../../components/global/Image';
// import Image from '../../components/global/Image';

// import NumbersCarouselItem from './NumbersCarouselItem';

// import RacingCarouselItem from './RacingCarouselItem';
// import Swiper from '../../components/global/swiper';
// // import {endpoint} from '../../client/SisClient';
// import Image from '../../components/global/Image';
// import {imageFromAPI} from '../../utils';
import {useEffect, useState} from 'react';
import {View, StyleSheet} from 'react-native';
import Carousel from './Carousel/';
import React from 'react';

// type Props = {};

const HomePageCarousel = ({carousel}) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);

  const [screen, setScreen] = useState('phone');

  useEffect(() => {
    if (containerWidth > 900) return setScreen('desktop');
    if (containerWidth > 600) return setScreen('tablet');
    return setScreen('phone');
  }, [containerWidth]);

  const heightMap = {
    desktop: 335,
    tablet: 250,
    phone: 200,
  };

  return (
    <View
      onLayout={e => {
        setContainerWidth(e.nativeEvent.layout.width);
        setContainerHeight(e.nativeEvent.layout.height);
      }}
      style={[styles.container, {height: heightMap[screen]}]}>
      <Carousel
        carousel={carousel}
        containerHeight={containerHeight}
        containerWidth={containerWidth}
        screen={screen}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginBottom: 10,
    borderRadius: 5,
    // overflow: 'hidden',
  },
});

export default HomePageCarousel;
