import {Linking, Pressable} from 'react-native';
import React, {useContext, useEffect, useState} from 'react';
import {imageFromAPI} from '../../utils';

import APIImage from '../../components/global/FnsImage/';
import AppContext from '../../context/AppContext';

type Props = {
  item: any;
  maxWidth?: number;
  parent: 'popup' | 'content-block' | 'standard';
};

const PartnerOffer = ({item, maxWidth, parent}: Props) => {
  const [containerWidth, setContainerWidth] = useState<number | undefined>(0);

  const context = useContext(AppContext);
  const desktop = context.platform === 'desktop';
  const platformMW = desktop ? 400 : 326;
  const mw: number | undefined =
    maxWidth && maxWidth > platformMW ? platformMW : maxWidth;

  useEffect(() => {}, [mw]);

  const chooseImage = () => {
    if (desktop) {
      if (parent === 'popup') {
        return item.offer_image_popup_url;
      }
      if (parent === 'content-block') return item.offer_image_content_block_url;
      return item.offer_image_url;
    } else {
      if (parent === 'popup') {
        return item.offer_image_popup_url_mobile;
      }
      if (parent === 'content-block')
        return item.offer_image_content_block_url_mobile;
      return item.offer_image_url_mobile;
    }
  };

  const dimensionMap: Record<string, {width: number; height: number}> = {
    popup: {
      width: 150,
      height: 75,
    },
    'content-block': {
      width: 100,
      height: 100,
    },
    standard: {
      width: 200,
      height: 100,
    },
  };

  const imageWithDimensions = () => {
    const url = chooseImage();
    const isGif = url.includes('.gif');
    if (isGif) {
      return imageFromAPI(url);
    } else {
      return imageFromAPI(url, {
        width: dimensionMap[parent].width,
        height: dimensionMap[parent].height,
      });
    }
  };

  return (
    <Pressable
      onPress={() => Linking.openURL(item.website)}
      style={{
        flex: 1,
        position: 'relative',
        borderRadius: 5,
        overflow: 'hidden',
        maxWidth: mw,
        width: '100%',
      }}
      onLayout={e => {
        if (mw >= platformMW) {
          setContainerWidth(mw);
        } else {
          setContainerWidth(e.nativeEvent.layout.width);
        }
      }}>
      <APIImage
        fetchpriority={parent === 'popup' ? 'high' : 'medium'}
        loading={parent === 'popup' ? 'eager' : 'lazy'}
        resizeMode={'cover'}
        style={{width: containerWidth, height: '100%'}}
        imageURL={imageFromAPI(imageWithDimensions())}
      />
    </Pressable>
  );
};

export default PartnerOffer;
