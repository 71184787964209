import React, {useEffect, useState, useContext} from 'react';

import AdvertisingBanner from './AdvertisingBanner';
import DesktopBanner from './DesktopBanner';
import Swiper from '../global/swiper/';

import AppContext from '../../context/AppContext';

import {AdvertisementProps, leaderboardObj} from '../../@types/pages'; // eslint-disable-line @typescript-eslint/no-unused-vars

const AdvertisementSwiper: React.FC<AdvertisementProps> = ({
  duration,
  placementType,
  advertisements,
  style = {},
  controlsEnabled = true,
}) => {
  const [ads, setAds] = useState([] as Array<leaderboardObj>);
  const [loading, setLoading] = useState(true);
  const context = useContext(AppContext);
  const desktop = context.platform === 'desktop';

  const Component = desktop ? DesktopBanner : AdvertisingBanner;

  useEffect(() => {
    const tempArr = [
      advertisements[advertisements.length - 2],
      advertisements[advertisements.length - 1],
      ...advertisements,
      advertisements[0],
      advertisements[1],
    ] as Array<leaderboardObj>;

    setAds(tempArr);
    setLoading(false);
  }, [advertisements]);

  if (loading) {
    return null;
  }

  const sliderStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...style,
  } as any;

  return (
    <Swiper
      controlsEnabled={controlsEnabled}
      from={2}
      timeout={duration || 6}
      loop={true}
      minDistanceForAction={0.25}
      springConfig={{speed: 100}}
      slideWrapperStyle={sliderStyle}
      actualAdLength={advertisements.length}>
      {ads.map((ad, index) => {
        return (
          <Component
            key={`TopAd${index}`}
            advertisement={ad}
            placementType={placementType}
            style={style}
          />
        );
      })}
    </Swiper>
  );
};

export default AdvertisementSwiper;
