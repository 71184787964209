import React, {useContext} from 'react';
import {View} from 'react-native';

import Row from '../../global/Row';
import Text from '../../text/FnsText';

import {getOrdinalNum, whiteOrBlackText} from '../../../utils';
import AppContext from '../../../context/AppContext';

import GlobalStyles from '../../../assets/styles';

import {PlacingRowProps} from '../../../@types/components'; // eslint-disable-line @typescript-eslint/no-unused-vars

const PlacingRow: React.FC<PlacingRowProps> = ({runner, bgColor, size}) => {
  const context = useContext(AppContext);
  const desktop = context.platform === 'desktop';
  const positionBG = {backgroundColor: bgColor};
  const textColor = {color: whiteOrBlackText(bgColor)};
  const textSize = size || (desktop ? 'SmallMedium' : 'Small');

  return (
    <Row
      style={[
        GlobalStyles.lightGrayBG,
        GlobalStyles.borderBottomWhite,
        GlobalStyles.borderBottomNarrow,
      ]}>
      <View style={[positionBG, GlobalStyles.iconWidthSmallMedium]}>
        <Text
          size={textSize}
          style={[
            GlobalStyles.paddingVerticalXS,
            GlobalStyles.textCenter,
            textColor,
          ]}>
          {getOrdinalNum(runner.finish_position)}
        </Text>
      </View>
      <Text
        numberOfLines={1}
        size={textSize}
        decoration={'SemiBold'}
        style={[
          GlobalStyles.flexGrow,
          GlobalStyles.paddingVerticalXS,
          desktop
            ? GlobalStyles.paddingHorizontalXS
            : GlobalStyles.paddingLeftSM,
        ]}>
        {`${runner.number} ${runner.name}`}
      </Text>
      <Text
        size={textSize}
        decoration={'SemiBold'}
        style={[
          GlobalStyles.paddingVerticalXS,
          desktop
            ? GlobalStyles.paddingHorizontalXS
            : GlobalStyles.paddingRightXS,
        ]}>
        {runner.starting_price?.fractional || ''}
      </Text>
    </Row>
  );
};

export default PlacingRow;
