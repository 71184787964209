import {PixelRatio} from 'react-native';

import DateAndTime from 'date-and-time';

import Client, {endpoint} from '../client/SisClient';
import Alerts from './Alerts';

import Colours from '../assets/Colours';

import {LinkObj, StringIndex} from '../@types/global'; // eslint-disable-line @typescript-eslint/no-unused-vars

/**
 * Ensure our paths begin with a forward slash
 */

export const cleanLinkPath = (path: string) => {
  return path.startsWith('/') ? path : `/${path}`;
};

/**
 * Given a path param, a call is made to get the content for that specific page
 *
 * If we don't have a successful response, we will:
 *   - Display a NotFound alert if the response was a 404
 *   - Display a UnexpectedError alert otherwise
 */

export const loadPageContent = async (path: string) => {
  try {
    const cleanedPath = path.includes('fount') ? 'the-fount' : path;
    const pageContent = Client.getPageContent(cleanedPath);
    const pageAdvertising = Client.getPageAdvertising(path);
    const resp = await Promise.all([pageContent, pageAdvertising]);

    return {
      displayBookmakers: resp[0].content && resp[0].content.display_bookmakers,
      content: resp[0].content && resp[0].content.content,
      advertising: resp[1].content && resp[1].content.advertising,
      headerImage: resp[0]?.content?.header_image_url,
    };
  } catch (err) {
    if (err.status === 404) {
      return Alerts.notFoundError();
    }

    Alerts.unexpectedError();
  }
};

/**
 * Active record provides a local URL for a file.
 * This function will return the complete URL, if the path exists, otherwise an empty string
 */
export const imageFromAPI = (
  path?: string,
  options?: {width?: number; height?: number; noScale?: boolean},
) => {
  if (!path) {
    return '';
  }

  let url;
  if (path.match(/^https?:\/\//)) {
    url = path;
  } else {
    url = `${endpoint}${path}`;
  }

  options = options || {};
  const isGif = path.includes('.gif');

  let query = [];
  let scale = PixelRatio.get();

  if (options.width && !isGif) {
    let width = options.width * (options.noScale ? 1 : scale);
    query.push(`width=${width}`);
  }
  if (options.height && !isGif) {
    let height = options.height * (options.noScale ? 1 : scale);
    query.push(`height=${height}`);
  }

  if (query.length > 0) {
    url = url.concat('?', query.join('&'));
  }

  return url;
};

/**
 * Use the background colour to apply white or black text
 * Returns either white or black hex code
 */
/* eslint-disable no-bitwise */
export const whiteOrBlackText = (bg: string, opposite?: boolean) => {
  const rgb = parseInt(bg.substring(1), 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  const logic = opposite ? !(luma < 150) : luma < 150;
  return logic ? Colours.white : Colours.black;
};

/**
 * Get human readable days / hours / mins from date string
 */
export const pullDaysHoursMinsFromDateString = (
  time: string,
  longForm: boolean = false,
) => {
  const days = Math.floor(
    DateAndTime.subtract(new Date(time), new Date()).toDays(),
  );
  const hours =
    Math.floor(DateAndTime.subtract(new Date(time), new Date()).toHours()) -
    24 * days;
  const mins =
    Math.floor(DateAndTime.subtract(new Date(time), new Date()).toMinutes()) -
    (60 * 24 * days + 60 * hours);
  const secs =
    Math.floor(DateAndTime.subtract(new Date(time), new Date()).toSeconds()) -
    (60 * 24 * days + 60 * hours + 60 * mins);

  if (days <= -1) {
    return '';
  }

  if (mins === 0 && hours === 0 && days === 0) {
    if (secs <= 0) {
      return 'live';
    }
    return `${secs} Second${secs === 1 ? '' : 's'}`;
  }

  if (longForm) {
    return `${days ? `${days} Day${days === 1 ? '' : 's'} ` : ''}${
      hours ? `${hours} hrs${hours === 1 ? '' : 's'} ` : ''
    }${mins ? `${mins} m${mins === 1 ? '' : 's'}` : ''}`;
  }

  return days
    ? `${days} day${days === 1 ? '' : 's'}`
    : `${hours ? `${hours} hrs${hours === 1 ? '' : ''} ` : ''}${mins} m${
        mins === 1 ? '' : ''
      }`;
};

/**
 * Plug in a number, and return the position, i.e. getOrdinalNum(1), will return 1st
 */
export const getOrdinalNum = (n: number) => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;

  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};
