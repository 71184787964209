/**
 * Component to display image with standard setup.
 */

import React from 'react';
// import {StyleSheet} from 'react-native';

import {ImageProps} from '../../@types/components'; // eslint-disable-line @typescript-eslint/no-unused-vars

const FnsImage: React.FC<ImageProps> = ({
  imageURL,
  style,
  loading = 'lazy',
  fetchpriority = 'medium',
}) => (
  <img
    style={{...style}}
    src={imageURL}
    loading={loading}
    fetchpriority={fetchpriority}
    alt="FnsImage"
  />
);

// const styles = StyleSheet.create({
//   image: {
//     width: '100%',
//     height: 'auto',
//   },
// });

export default FnsImage;
