/**
 * Component that will maintain an aspect ratio for a video / image / text to sit in
 * It will default to the standard 9:16, but this can be overriden by adding `paddingTop` to the `style` prop
 */

import React from 'react'
import {View, StyleSheet, StyleProp, ViewStyle, Platform} from 'react-native' // eslint-disable-line @typescript-eslint/no-unused-vars

import {ChildrenAndStyleProps} from '../../@types/global' // eslint-disable-line @typescript-eslint/no-unused-vars

interface props extends ChildrenAndStyleProps {
  contentStyle?: StyleProp<ViewStyle>;
}
const AspectRatio: React.FC<props> = ({
  children,
  style,
  contentStyle,
}) => {
  return (
    <View style={[styles.aspectRatioBox, style]}>
      <View style={[styles.aspectRatioContent, contentStyle]}>
        {children}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  aspectRatioBox: {
    height: Platform.OS === 'web' ? 0 : 120,
    overflow: 'hidden',
    paddingTop: Platform.OS === 'web' ? 'min(56.25%, 425px)' : 0, // 9 / 16 = 56.25%
    position: 'relative',
  },
  aspectRatioContent: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default AspectRatio
