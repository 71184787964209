/**
 * A component to render a LOtto Ball
 * It will be a Pressable component with a background equal to the correct hexColor
 *
 * Args:
 * - Size: How large we want the ball
 * - Color: BG colour of the ball
 *
 * TODO: Will need to do the inset shadow for ios and Android
 */

import React from 'react'
import {Pressable, View, StyleProp, ViewStyle, Platform, Image} from 'react-native' // eslint-disable-line @typescript-eslint/no-unused-vars

import Text from '../text/FnsText'

import GlobalStyles from '../../assets/styles'
import Layout from '../../assets/Layout'
import lottoBallColours from '../../assets/styles/lottoBallColours'

import {BallProps} from '../../@types/components' // eslint-disable-line @typescript-eslint/no-unused-vars
import {StringIndex} from '../../@types/global' // eslint-disable-line @typescript-eslint/no-unused-vars


const circleStyle = (iconSize: number, multiplier = 1) => ({
  height: iconSize * multiplier,
  width: iconSize * multiplier,
  borderRadius: iconSize / 2,
})

// The sizes do not correlate nicley into text, so we bump them up instead
const textMapping = {
  Medium: 'Large',
  MediumLarge: 'ExtraLarge',
} as StringIndex

const LottoBall: React.FC<BallProps> = ({product, number, size, onPress, style}) => {

  const getBallColor = ( ballProduct: string, ballnumber:string) => {
    const productBallColors = lottoBallColours[product]
    let col
    if (ballProduct !== '49s') {
      Object.keys(productBallColors).forEach((key: string) => {
        const splitKey = key.split('-')
        let num = parseInt(ballnumber, 10)
        if (num >= parseInt(splitKey[0], 10) && num <= parseInt(splitKey[1], 10)) {col = productBallColors[key]}
      })
    } else {
      const num = parseInt(ballnumber, 10) % 7
      col = lottoBallColours['49s'][num ? num : 7]
    }
    return col
  }

  const iconSize = Layout[`iconSize${size}`]
  const bgColor = {backgroundColor:getBallColor(product, number) }
  let shadowStyle = {
    opacity: 0.6,
    position: 'absolute',
    boxShadow: `${-(iconSize * 0.075)}px ${-(iconSize * 0.10)}px ${iconSize * 0.15}px #000 inset`,
  } as StyleProp<ViewStyle>
  // remove ball shadow on lotto365


  return (
    <Pressable
      onPress={onPress ? () => onPress(number) : undefined}
      style={[circleStyle(iconSize), bgColor, GlobalStyles.justifyCenter, GlobalStyles.alignCenter, style]}>

      {Platform.select({
        web: <View style={[circleStyle(iconSize), shadowStyle]} />,
        default: <Image source={require('../../Images/ball_alpha.png')} style={[circleStyle(iconSize), {position: 'absolute'}]}/>,
      })}

      <View style={[product !== 'lotto365' && GlobalStyles.bodyBG, GlobalStyles.justifyCenter, GlobalStyles.alignCenter, circleStyle(iconSize, 0.65), {flex: Platform.OS === 'web' ? undefined : 0.65}]}>
        <Text style={{fontWeight: 'bold'}} decoration={product !== 'lotto365' ? 'Bold' : 'Futura'} size={textMapping[size] || size}>
          {number}
        </Text>
      </View>
    </Pressable>
  )
}

export default LottoBall
