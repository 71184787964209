import React, {useContext, useEffect, useState} from 'react';
import {StyleSheet, Pressable, View} from 'react-native';

import Image from '../global/Image';
import Text from '../text/FnsText';

import {
  imageFromAPI,
  pullDaysHoursMinsFromDateString,
  // whiteOrBlackText,
} from '../../utils';

import Layout from '../../assets/Layout';
import Colours from '../../assets/Colours';
import GlobalStyles from '../../assets/styles';

import {ProductLogoItemProps} from '../../@types/components'; // eslint-disable-line @typescript-eslint/no-unused-vars
import AppContext from '../../context/AppContext';

///////////// Variables
const margin = Layout.spacer;

///////////// Functional Component
const ProductLogoItem: React.FC<ProductLogoItemProps> = ({
  product,
  time,
  onPress,
  selectedIcon,
  bottomTriangle,
  blendToSubNav,
  updateNextToGo,
}) => {
  const context = useContext(AppContext);
  const {navItems, platform, currentStack} = context;
  const isDesktop = platform === 'desktop';

  const [timeRemaining, setTimeRemaining] = useState<string>();

  useEffect(() => {
    const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
    if (time) {
      const timer = setInterval(() => {
        const newTime = pullDaysHoursMinsFromDateString(time);
        if (newTime === 'live') {
          // wait 5 seconds before updating next to go
          wait(10000).then(() => {
            updateNextToGo && updateNextToGo();
          });
        }
        setTimeRemaining(newTime);
      }, 1000);

      return () => {
        clearTimeout;
        clearInterval(timer);
      };
    }
  }, [time, updateNextToGo]);

  const secondaryMenu = navItems[`secondary/${product?.path}`];
  if (!secondaryMenu) {
    return null;
  }
  const secondaryLogo = secondaryMenu?.logo_image_url;

  const url = imageFromAPI(secondaryLogo);

  const islotto365 = product?.path === 'lotto365';

  // Styles
  const iconHeight = isDesktop
    ? Layout.advertisingBannerS
    : Layout.iconSizeMediumLarge;
  const iconWidth = isDesktop
    ? Layout.advertisingBannerM
    : Layout.iconSizeExtraLarge;
  const iconHeightStyle = {height: islotto365 ? iconHeight + 10 : iconHeight};
  const iconWidthStyle = {width: iconWidth};
  const bgColour = {backgroundColor: secondaryMenu.colors.background_color};
  let marginBottom =
    selectedIcon && !blendToSubNav ? {marginBottom: margin} : {};
  const trianglestyle = {
    borderBottomColor: Colours[product?.path || ''],
    left: iconHeight / 2,
  };

  // const textColor = {
  //   color:
  //     whiteOrBlackText(secondaryMenu.colors.background_color) || Colours.white,
  // };
  // const alternatingColor =
  //   timeRemaining?.includes('econd') && parseInt(timeRemaining, 10) % 2 === 0
  //     ? {
  //         color:
  //           whiteOrBlackText(secondaryMenu.colors.background_color, true) ||
  //           Colours.black,
  //       }
  //     : undefined;
  const isCurrentStack =
    currentStack && currentStack.includes(product?.display_path || '');

  return (
    <View
      style={{paddingBottom: isDesktop ? 0 : 10}}
      key={`navLogo${product?.path}`}>
      <Pressable
        onPress={() => onPress(product)}
        style={[
          platform === 'desktop'
            ? GlobalStyles.marginRightSM
            : GlobalStyles.marginRightXS,
          isCurrentStack
            ? GlobalStyles.borderRadiusTopSM
            : GlobalStyles.borderRadiusSM,
          !islotto365 && bgColour,
          marginBottom,
          GlobalStyles.hiddenOverflow,
          {paddingBottom: islotto365 ? 0 : 10},
        ]}>
        <View
          style={[
            GlobalStyles.alignCenter,
            GlobalStyles.justifyCenter,
            iconHeightStyle,
            iconWidthStyle,
          ]}>
          <Image
            resizeMode={'cover'}
            imageURL={imageFromAPI(url, {
              width: iconWidth,
              height: islotto365 ? iconHeight + 10 : iconHeight,
            })}
          />
        </View>

        {time && (
          <View
            style={[
              {
                justifyContent: 'center',
                alignItems: 'center',
                paddingVertical: 0,
                position: 'absolute',
                width: '100%',
                backgroundColor: 'rgba(0,0,0,0.3)',
                bottom: 0,
              },
            ]}>
            <Text
              decoration={'Bold'}
              style={[
                {
                  color: Colours.white,
                },
              ]}
              size={'Small'}>
              {timeRemaining}
            </Text>
            {/* <Text style={[{color: Colours.white}]} size={'ExtraSmall'}>
              {!timeRemaining || timeRemaining === 'live' ? '' : ''}
            </Text> */}
          </View>
        )}
      </Pressable>

      {isCurrentStack && !bottomTriangle && (
        <View
          style={[iconWidthStyle, GlobalStyles.layoutSpacerSMHeight, bgColour]}
        />
      )}

      {bottomTriangle && selectedIcon === product?.title ? (
        <View style={[styles.triangle, trianglestyle]} />
      ) : null}
    </View>
  );
};

///////////// Styles
const styles = StyleSheet.create({
  triangle: {
    position: 'absolute',
    bottom: margin / 2,

    borderLeftWidth: Layout.spacerSM,
    borderRightWidth: Layout.spacerSM,
    borderBottomWidth: Layout.spacerSM,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    transform: [{rotate: '180deg'}],
  },
});

export default ProductLogoItem;
