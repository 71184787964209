import {Text, View, StyleSheet, Image, Button} from 'react-native';
import React, {useState} from 'react';
import LogoImage from '../../Images/logo.png';
import {TextInput} from 'react-native-gesture-handler';
import Client from '../../client/SisClient';

//  one hour in milliseconds
const oneMinute = 60 * 1000;
const oneHour = 60 * oneMinute;
const oneDay = 24 * oneHour;
const oneMonth = 30 * oneDay;

const Partners = () => {
  const [token, setToken] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handlePress = () => {
    if (!token) return;
    Client.postPartnerToken(token)
      .then(res => {
        if ('error' in res.content) return setError(res.content.error);

        const item = {
          value: token,
          expiry: new Date().getTime() + oneMonth,
          refreshAt: new Date().getHours() + 1,
        };

        window.localStorage.setItem('fns_partner_token', JSON.stringify(item));
        window.location.replace(window.location.origin);
        setError('Success');
      })
      .catch(err => setError(err));
  };

  return (
    <View style={styles.container}>
      <View style={styles.topSection}>
        <Image style={{height: 100, width: 100}} source={LogoImage} />
        <Text style={styles.text}>Partners</Text>
      </View>
      <View style={{minWidth: 300}}>
        <TextInput
          placeholder="Enter Token"
          onChangeText={text => setToken(text)}
          style={styles.input}
        />
        <Button onPress={() => handlePress()} title="Submit" />
        <View style={styles.errorContainer}>
          {!!error && <Text style={styles.text}>{error}</Text>}
        </View>
      </View>
    </View>
  );
};

export default Partners;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    paddingTop: '5%',
    backgroundColor: 'blue',
  },
  topSection: {
    flexDirection: 'row',
    alignItems: 'center',

    width: '40%',
    justifyContent: 'center',
  },
  text: {
    color: 'white',
    fontSize: 24,
    marginLeft: 10,
  },
  input: {
    height: 40,
    width: '100%',
    borderColor: 'gray',
    borderWidth: 1,
    marginVertical: 20,
    backgroundColor: 'white',
    padding: 5,
  },
  errorContainer: {
    marginTop: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
