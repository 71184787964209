/**
 * To make API calls, we will leverage Onpace's js-api Library:
 * https://github.com/onpace/js-api
 *
 * This acts as a wrapper for XMLHttpRequest
 * If we receive a happy path response, we will receive a value { success: true }
 * Otherwise, we will receive a value { success: false }
 */

import DateAndTime from 'date-and-time';
import Ordinal from 'date-and-time/plugin/ordinal';
DateAndTime.plugin(Ordinal);
import {Platform} from 'react-native';

import {sendRequest} from 'js-api';

///////////// Endpoints

const endpoints: {[key: string]: string} = {
  production: 'https://49s-api.production.sis.onpacegroup.com',
  staging: 'https://49s-api.staging.sis.onpacegroup.com',
  development: process.env.REACT_APP_DEV_URL || 'http://localhost:4100',
};

const appReferer: {[key: string]: string} = {
  production: '49s-app.production.sis.onpacegroup.com',
  staging: '49s-app.staging.sis.onpacegroup.com',
  development: 'localhost',
};

const frontendStaticEndpoint: {[key: string]: string} = {
  production: Platform.OS === 'web' ? '' : 'https://49s.co.uk',
  staging: 'https://49s-app.staging.sis.onpacegroup.com',
  development: 'https://49s-app.staging.sis.onpacegroup.com',
};

import ENV from './ClientEnv';
import {userCountry} from '../components/navigation/Utils';

let environment = ENV;
console.log(`Environment:${environment}`);
export const endpoint = endpoints[environment];
export const frontendStaticEndpointValue = frontendStaticEndpoint[environment];

const countryOverride = process.env.REACT_APP_DEV_COUNTRY || 'GB';

const numbersGames: string[] = [
  '49s',
  '39s',
  '15s',
  'lotto365',
  'irish-lotto-bet',
];

const racingProducts: string[] = [
  'virtual-horse-racing',
  'virtual-greyhound-racing',
];

const allProducts: string[] = [...numbersGames, ...racingProducts];

///////////// API Request

// Options
type Options = {
  headers?: any;
  params?: object;
};

// Request
const sendApiRequest = async (
  method: string,
  path: string,
  options: Options = {},
) => {
  // Update Path
  let url = `${endpoint}${path}`;

  if (Platform.OS === 'web') {
    const partner_token = window.localStorage.getItem('fns_partner_token');
    if (partner_token) {
      const parsedToken = JSON.parse(partner_token);
      const now = new Date();
      const inOneHour = now.getHours() + 1;
      let refreshAt;

      if (parsedToken.expiry < now.getTime()) {
        window.localStorage.removeItem('fns_partner_token');
        window.location.reload();
      } else {
        const shouldRefresh = parsedToken.refreshAt < inOneHour;

        if (shouldRefresh) {
          const updateTokenValues = {
            ...parsedToken,
            refreshAt: inOneHour,
          };
          window.localStorage.setItem(
            'fns_partner_token',
            JSON.stringify(updateTokenValues),
          );
          refreshAt = inOneHour;
        } else {
          refreshAt = parsedToken.refreshAt;
        }

        url = url.includes('?')
          ? (url = `${url}&partner_token=${parsedToken.value}&refresh_at=${refreshAt}`)
          : (url = `${url}?partner_token=${parsedToken.value}&refresh_at=${refreshAt}`);
      }
    }
  }

  if (!options.headers) {
    options.headers = {};
  }
  if (process.env.NODE_ENV === 'development') {
    options.headers['CloudFront-Viewer-Country'] = countryOverride;
  }
  if (Platform.OS !== 'web') {
    options.headers['Referer'] = appReferer[environment];
  }
  options.headers['X-Requested-With'] = 'XMLHttpRequest';

  // Send Request
  const response = await sendRequest(method, url, options);
  return response;
};

// Human Readable Date
const humanReadableDate = (date: Date) =>
  DateAndTime.format(new Date(date), 'YYYY-MM-DD');

///////////// API Calls

const defaultMetaCall = async () => {
  return await sendApiRequest('GET', '/meta');
};

const getFromFrontendStatic = async (path: string) => {
  try {
    const res = await fetch(`${frontendStaticEndpointValue}/cache/${path}`);
    const data = await res.json();
    return {
      content: data,
      success: true,
    };
  } catch (error) {
    return {
      content: [],
      success: false,
    };
  }
};

export default {
  // API Status
  getAPIStatus: () => {
    return sendApiRequest('GET', '/');
  },

  // Initial call to load menus
  loadAppData: async () => {
    try {
      const partner_token =
        Platform.OS === 'web' &&
        window.localStorage.getItem('fns_partner_token');
      if (partner_token) throw new Error('Token exists default fetch');
      const country = userCountry();
      if (!country) throw new Error('Country not found default fetch');

      return await getFromFrontendStatic(`meta_${country}`);
    } catch (error) {
      return await defaultMetaCall();
    }
  },

  // Get specific page content
  getPageContent: async (path: string) => {
    // return sendApiRequest('GET', `/pages/${path}`);

    try {
      // const numbersGames = ['49s', '39s', '15s', 'lotto365', 'irish-lotto-bet'];

      if (!allProducts.includes(path))
        throw new Error('Not a numbers game default fetch');
      const country = userCountry();
      if (!country) throw new Error('Country not found default fetch');

      return await getFromFrontendStatic(`pages/${path}_${country}`);
    } catch {
      return sendApiRequest('GET', `/pages/${path}`);
    }
  },
  // Get specific page advertising
  getPageAdvertising: async (path: string) => {
    try {
      if (!allProducts.includes(path))
        throw new Error('Not a numbers game default fetch');
      const country = userCountry();
      if (!country) throw new Error('Country not found default fetch');

      return await getFromFrontendStatic(`advertising/${path}_${country}`);
    } catch {
      return sendApiRequest('GET', `/advertising/${path}`);
    }
  },

  // Send advertising click information
  sendAdvertisingClickInformation: (
    campaignID: string,
    placementType: string,
  ) => {
    return sendApiRequest(
      'GET',
      `/advertising/clicked/${campaignID}/${placementType}`,
    );
  },

  // Blogs

  // Get all blogs
  getBlogs: (page: number) => {
    return sendApiRequest('GET', `/blog_posts${page ? `?page=${page}` : ''}`);
  },

  // Get a specific blog
  getBlog: (id: number) => {
    return sendApiRequest('GET', `/blog_posts/${id}`);
  },

  // get all recent blogs
  getRecentBlogs: () => {
    return sendApiRequest('GET', '/blog_posts/recent');
  },

  // get homepage grid
  getHomePageGrid: async () => {
    try {
      const country = userCountry();
      if (!country) throw new Error('Country not found default fetch');
      return await getFromFrontendStatic(`home_page_${country}`);
    } catch {
      return sendApiRequest('GET', '/home_page');
    }
  },

  // get homepage carousel
  getHomePageCarousel: async () => {
    try {
      const country = userCountry();
      if (!country) throw new Error('Country not found default fetch');
      return await getFromFrontendStatic(`home_page/carousel_${country}`);
    } catch (error) {
      return sendApiRequest('GET', '/home_page/carousel');
    }
  },

  // get Partner Offers
  getPartnerOffers: async () => {
    try {
      const country = userCountry();
      if (!country) throw new Error('Country not found default fetch');
      return await getFromFrontendStatic(`partner_offer_${country}`);
    } catch {
      return sendApiRequest('GET', '/partner_offer');
    }
  },

  // get popup offers
  getPartnerOffersModal: async () => {
    try {
      const country = userCountry();
      if (!country) throw new Error('Country not found default fetch');
      return await getFromFrontendStatic(`partner_offer/pop-up-${country}`);
    } catch {
      return sendApiRequest('GET', '/partner_offer/pop-up');
    }
  },

  // Get all Presenters
  getPresenters: () => {
    return sendApiRequest('GET', '/presenters');
  },

  // Get all Bookmakers
  getBookmakers: () => {
    return sendApiRequest('GET', '/bookmakers');
  },

  // HomePage

  // Get latest result for products
  getLatestResults: () => {
    return sendApiRequest('GET', '/results/latest');
  },
  // Get next to go
  getNextToGo: async () => {
    try {
      return await getFromFrontendStatic('next-to-go');
    } catch (error) {
      return {
        content: [],
        success: false,
      };
    }
  },

  // Numbers

  // Get trending numbers
  getTrendingNumbers: async (code: string) => {
    try {
      return await getFromFrontendStatic(`numbers/${code}/stats/trending`);
    } catch (error) {
      return {
        content: [],
        success: false,
      };
    }
  },
  // Get stats for all numbers
  getAllNumbersStats: (code: string) => {
    return sendApiRequest('GET', `/numbers/${code}/stats/all-balls`);
  },

  // Get permutations for check my numbers
  getNumberPermutations: (
    code: string,
    numbers: Array<string>,
    options: {dateFrom?: Date; dateTo?: Date; eventNumbers?: string},
  ) => {
    const queryParams = `?numbers=${
      typeof numbers !== 'string' ? numbers.join() : numbers
    }${
      options.dateFrom
        ? `&date_from=${humanReadableDate(options.dateFrom)}`
        : ''
    }${options.dateTo ? `&date_to=${humanReadableDate(options.dateTo)}` : ''}${
      options.eventNumbers ? `&event_numbers=${options.eventNumbers}` : ''
    }`;
    return sendApiRequest(
      'GET',
      `/numbers/${code}/stats/check-my-numbers${queryParams}`,
    );
  },

  // Results

  // Get latest results for products homepage
  getLatestResultForProduct: async (code: string) => {
    // let requestString = `/numbers/${code}/events/recent`;
    // if (code === '39' || code === '36') {
    //   requestString = requestString + '?limit=5';
    // }
    // return sendApiRequest('GET', requestString);
    try {
      return await getFromFrontendStatic(`numbers/${code}/events/recent`);
    } catch (error) {
      return {
        content: [],
        success: false,
      };
    }
  },

  // Get Result by Date
  getProductResultByDate: (code: string, date: Date) => {
    return sendApiRequest(
      'GET',
      `/numbers/${code}/events/${humanReadableDate(date)}`,
    );
  },

  // Get previous results for products results page
  getPreviousResultForProduct: async (code: string, limit?: number) => {
    try {
      return await getFromFrontendStatic(`numbers/${code}/games/recent`);
    } catch {
      return sendApiRequest(
        'GET',
        `/numbers/${code}/games/recent${limit ? `?limit=${limit}` : ''}`,
      );
    }
  },

  // Get all Meeting info for Virtuals product
  getRaceMeeting: async (category: string, date: Date) => {
    try {
      const resp = await getFromFrontendStatic(
        `racing/${category}/meetings/${humanReadableDate(date)}`,
      );

      if (resp.success) {
        return resp;
      } else {
        throw new Error('Not found in frontend static fetch from Api');
      }
    } catch {
      return sendApiRequest(
        'GET',
        `/racing/${category}/meetings/${humanReadableDate(date)}`,
      );
    }
  },

  // Get details for an individual Race
  getRaceInformation: (
    category: string,
    date: Date,
    meeting: string,
    event: number,
  ) => {
    return sendApiRequest(
      'GET',
      `/racing/${category}/meetings/${humanReadableDate(
        date,
      )}/${meeting}/events/${event}`,
    );
  },

  // Runners

  // Search runners by name
  getRunnersByName: (category: string, query: string) => {
    return sendApiRequest(
      'GET',
      `/racing/${category}/runners/search?query=${query}`,
    );
  },
  // Get runner details by id
  getRunnerDetailsByID: (
    category: string,
    id: number,
    dateFrom?: Date,
    dateTo?: Date,
  ) => {
    const queryParams = `${
      dateFrom ? `date_from=${humanReadableDate(dateFrom)}` : ''
    }${dateTo ? `date_to=${humanReadableDate(dateTo)}` : ''}`;
    return sendApiRequest(
      'GET',
      `/racing/${category}/runners/${id}?${queryParams}`,
    );
  },

  // Contact Form Endpoint
  sendContactSubmission: (name: string, email: string, message: string) => {
    return sendApiRequest('POST', '/contacts/create', {
      params: {
        contact: {
          name: name,
          email: email,
          content: message,
        },
      },
    });
  },

  // Get FAQs
  getFAQs: () => {
    return sendApiRequest('GET', '/faqs');
  },

  // post partner token
  postPartnerToken: (token: string) => {
    return sendApiRequest('POST', '/partners', {
      params: {
        partner_token: token,
      },
    });
  },
};
