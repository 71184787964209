import React, {useContext} from 'react';
import Button from '../Button';
import GlobalStyles from '../../../assets/styles';
import {useNavigation} from '@react-navigation/core';
import AppContext from '../../../context/AppContext';

const ViewMorePartnerOffersBtn: React.FC = () => {
  const navigation = useNavigation();
  const context = useContext(AppContext);
  const {navItems, platform} = context;
  const desktop = platform === 'desktop';

  return (
    <Button
      text="View More Partner Offers Here"
      containerStyles={[
        GlobalStyles.whiteText,
        GlobalStyles.oceanBlueBG,
        GlobalStyles.borderRadiusLG,
        GlobalStyles.noBorder,
        GlobalStyles.justifyCenter,
        GlobalStyles.paddingXS,
      ]}
      textDecoration="Bold"
      onpress={() => {
        const navItem = navItems[`secondary/49s`].pages.find(
          p => p.path === `49s/bet-now`,
        )?.title;
        navigation.navigate(navItem);
      }}
      uppercase={false}
      textSize={desktop ? 'Medium' : undefined}
    />
  );
};

export default ViewMorePartnerOffersBtn;
