/**
 * Component to display Desktop Top and Bottom Banner
 */

import React from 'react';
import {Pressable, Linking} from 'react-native';

import Image from '../global/Image';

import Client, {endpoint} from '../../client/SisClient';

import GlobalStyles from '../../assets/styles';

import {AdvertisingProps} from '../../@types/components'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {useRoute} from '@react-navigation/native';

const DesktopBanner: React.FC<AdvertisingProps> = ({
  advertisement,
  placementType,
  style,
}) => {
  const {large_image_url, link, campaign_id, color} = advertisement;
  const path = `${endpoint}/${large_image_url}`;
  const bgColour = {backgroundColor: color};
  const [containerWidth, setContainerWidth] = React.useState(0);
  const [containerHeight, setContainerHeight] = React.useState(0);
  const route = useRoute();
  const isHomePage = route?.name === 'Home';

  return (
    <Pressable
      onLayout={e => {
        setContainerHeight(e.nativeEvent.layout.height);
        setContainerWidth(e.nativeEvent.layout.width);
      }}
      onPress={async () => {
        Client.sendAdvertisingClickInformation(campaign_id, placementType);
        Linking.openURL(link);
      }}
      style={[
        GlobalStyles.justifyCenter,
        GlobalStyles.alignCenter,
        GlobalStyles.fullHeight,
        GlobalStyles.borderRadiusSM,
        GlobalStyles.width95pct,
        bgColour,
        style,
        {
          // maxWidth: isHomePage ? 728 : undefined,
          maxHeight: isHomePage ? 90 : undefined,
        },
      ]}>
      <Image
        style={{height: containerHeight, width: containerWidth}}
        resizeMode={'contain'}
        imageURL={path}
        loading="eager"
      />
    </Pressable>
  );
};

export default DesktopBanner;
