import {Platform} from 'react-native';
import Client from '../../client/SisClient';

//get next to go and order by products

export const getOrderedNextToGo = async (providedNextToGo = undefined) => {
  const nextTogoOrder = {
    '49': 0,
    '36': 1,
    IL: 2,
    '39': 3,
    '15': 4,
    HR: 5,
    DG: 6,
  } as any;

  const nextToGo = !providedNextToGo && (await Client.getNextToGo());

  // order res  events by nextTogoOrder
  const ordered = [];
  (nextToGo || providedNextToGo)?.content?.events?.forEach(event => {
    const order = nextTogoOrder[event.sub_type];
    ordered[order] = event;
  });
  return ordered;
};

export const userCountry = Platform.select({
  default: () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (tz === 'Africa/Johannesburg') return 'ZA';
    else if (tz === 'Europe/London') return 'GB';
    return undefined;
  },
  android: () => undefined,
});

export const userIsInSa = Platform.select({
  default: () => userCountry() === 'ZA',
  android: () => false,
});

// export const updateNextToGo = async () => {
//   const resp = await Client.getNextToGo();
//   if (!resp.success) {
//     return Alerts.unexpectedError();
//   }

//   if (numbersResults) {
//     const newNumbersResults = {...numbersResults};

//     Object.keys(numbersResults).map(code => {
//       const nextTogo = resp.content.events.find(
//         (e: NextToGoProps) =>
//           allProducts.find(p => p.code === e.sub_type)?.path === code,
//       )?.time;
//       newNumbersResults[code].nextToGo = nextTogo;
//     });

//     setNumbersResults(newNumbersResults);
//   }

//   if (racingResults) {
//     const newRacingResults = {...racingResults};

//     Object.keys(racingResults).map(code => {
//       const nextTogo = resp.content.events.find(
//         (e: NextToGoProps) =>
//           allProducts.find(p => p.code === e.sub_type)?.path === code,
//       )?.time;
//       newRacingResults[code].nextToGo = nextTogo;
//     });

//     setRacingResults(newRacingResults);
//   }
// };
