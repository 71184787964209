/**
 * Component to display image with standard setup.
 */

import React from 'react'
import { Image, StyleSheet} from 'react-native'

import {ImageProps} from '../../@types/components' // eslint-disable-line @typescript-eslint/no-unused-vars

const FnsImage: React.FC<ImageProps> = ({imageURL, style, resizeMode}) => (
  <Image
    source={{uri: imageURL}}
    resizeMode={resizeMode || 'contain'}
    style={[styles.image, style]}
  />
)

const styles = StyleSheet.create({
  image: {
    width: '100%',
    height: '100%',
  },
})

export default FnsImage
