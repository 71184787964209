/**
 * A small component that will create a row, and render children.
 */

import React from 'react'
import {View} from 'react-native'

import GlobalStyle from '../../assets/styles'

import {ChildrenAndStyleProps} from '../../@types/global' // eslint-disable-line @typescript-eslint/no-unused-vars

const Row: React.FC<ChildrenAndStyleProps> = ({style, children}) => {
  return (
    <View style={[GlobalStyle.row, GlobalStyle.fullWidth, GlobalStyle.justifyBetween, style]}>
      {children}
    </View>
  )
}

export default Row
