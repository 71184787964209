import {View} from 'react-native';
import React, {useState} from 'react';

import Carousel from './Carousel/';

type Props = {};

const PartnerOfferModalContent = ({partner_offers}: Props) => {
  // const [partnerOffer, setPartnerOffer] = useState([]);
  // const [error, setError] = useState(false);
  const [containerHeight, setContainerHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  // useEffect(() => {
  //   Client.getPartnerOffersModal()
  //     .then(res => {

  //       setPartnerOffer(chunked);
  //     })
  //     .catch(() => setError(true));
  //   return () => {};
  // }, []);

  // if (loading)
  //   return (
  //     <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
  //       <ActivityIndicator size="large" color={Colours['49s']} />
  //     </View>
  //   );

  // if (error) return <Text>Something went wrong</Text>;

  const chunk = (arr, size) =>
    Array.from({length: Math.ceil(arr.length / size)}, (v, i) =>
      arr.slice(i * size, i * size + size),
    );
  const chunked = chunk(partner_offers, 3);

  return (
    <View
      style={{flex: 1}}
      onLayout={e => {
        setContainerHeight(e.nativeEvent.layout.height);
        setContainerWidth(e.nativeEvent.layout.width);
      }}>
      {containerHeight && containerWidth ? (
        <Carousel
          carousel={chunked}
          containerHeight={containerHeight - 50}
          containerWidth={containerWidth}
          screen={'phone'}
          isPartnerOffer={true}
        />
      ) : null}
    </View>
  );
};

export default PartnerOfferModalContent;

// const styles = StyleSheet.create({});
