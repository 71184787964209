/**
 * Types for src root
 */

///////////// Default Value for Linking Prop

export interface SampleLinking {
  prefixes: Array<string>;
  config: {
    screens: {
      [key: string]: string;
    };
  };
}

export const dynamicLinking = {
  prefixes: ['https://49s.com', '://'],
  config: {
    screens: {
      Partners: '/partners',
      BlogPost: '49s/offair/:title/:id',
      // Blogs: '49s/blogs', blogs page created on CMS pages
    },
  },
} as SampleLinking;
