/**
 * A utility file to handle different alerts that will be displayed throughtout the app
 *
 * TODO:
 * RN-WEB does not yet support Alerts: https://github.com/necolas/react-native-web/issues/1026
 * So we will need to come up with an alternative / use an alternative package
 */

import {Alert} from 'react-native'

type Options = {
  buttons?: Array<object>,
  buttonText?: string,
  callback?: Function
}

export const displayAlert = (title: string, message: string, options: Options = {}) => {
  let buttons = options.buttons

  if (!buttons) {
    let button = {
      text: options.buttonText || 'OK',
      onPress: options.callback || null,
    }

    buttons = [button]
  }

  return Alert.alert(title, message, buttons)
}

export default {
  // Errors

  notFoundError: () => {
    return displayAlert('Not Found', 'The specified resource is not available.')
  },
  unexpectedError: () => {
    return displayAlert('Unexpected Error', 'Please try again later.')
  },
}
