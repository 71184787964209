import {Modal, Pressable, View, StyleSheet} from 'react-native';
import React, {useContext, useEffect} from 'react';
import FnsText from '../../components/text/FnsText';
import Colours from '../../assets/Colours';
import PartnerOfferModalContent from './PartnerOfferModalContent';
import PopupTakeoverItem from './PopupTakeoverItem';
import ActivityIndicator from '../../components/global/ActivityIndicator';
import AppContext from '../../context/AppContext';

type Props = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  title: string;
  children: React.ReactNode;
  apiCall?: Function;
};

const FnsModal = ({visible, title, setVisible, apiCall}: Props) => {
  const [loading, setLoading] = React.useState(true);
  const [takeoverItems, setTakeoverItems] = React.useState<any[]>([]);
  const [partnerOffers, setPartnerOffers] = React.useState<any[]>([]);
  const [error, setError] = React.useState(false);
  const {platform} = useContext(AppContext);
  const desktop = platform === 'desktop';

  useEffect(() => {
    setLoading(true);
    apiCall &&
      apiCall()
        .then(res => {
          const {content} = res;

          if ('popup_takeovers' in content)
            setTakeoverItems(content.popup_takeovers);
          if ('partner_offers' in content) {
            setPartnerOffers(content.partner_offers);
          }
          if (partnerOffers.length || takeoverItems.length) {
            setVisible(true);
          }
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));

    return () => {};
  }, [apiCall, partnerOffers.length, takeoverItems.length, setVisible]);

  return (
    <Modal animationType="slide" transparent={true} visible={visible}>
      <View style={styles.overLay}>
        <View
          style={
            takeoverItems?.length
              ? styles.container
              : [styles.partnerOfferContainer, {maxHeight: desktop ? 740 : 630}]
          }>
          <FnsText
            size="MediumLarge"
            decoration="SemiBold"
            style={{
              color: Colours['49s'],
              padding: 20,
              textTransform: 'uppercase',
            }}>
            {takeoverItems[0]?.title || title}
          </FnsText>
          <Pressable
            onPress={() => setVisible(false)}
            style={{
              position: 'absolute',
              right: 20,
              top: 10,
              padding: 5,
            }}>
            <FnsText style={{color: Colours['49s']}} size="Large">
              X
            </FnsText>
          </Pressable>
          <View
            style={{
              flex: 1,
            }}>
            {error ? (
              <FnsText style={{padding: 10, color: Colours['49s']}}>
                Something went wrong, Please try again later
              </FnsText>
            ) : null}
            {loading ? (
              <View style={styles.center}>
                <ActivityIndicator size="extraLarge" color={Colours['49s']} />
              </View>
            ) : null}
            {!loading && !error && takeoverItems?.length ? (
              <PopupTakeoverItem
                website={takeoverItems[0]?.website}
                imageURL={
                  desktop
                    ? takeoverItems[0]?.takeover_image
                    : takeoverItems[0]?.takeover_image_mobile
                }
              />
            ) : null}
            {!loading && !error && partnerOffers.length ? (
              <PartnerOfferModalContent partner_offers={partnerOffers} />
            ) : null}
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default FnsModal;

const styles = StyleSheet.create({
  overLay: {
    flex: 1,
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  container: {
    flex: 1,

    width: '95%',
    maxWidth: 800,
    maxHeight: 800,
    backgroundColor: 'white',
    borderRadius: 5,
    overflow: 'hidden',
  },

  partnerOfferContainer: {
    flex: 1,

    width: '95%',
    maxWidth: 450,
    // maxHeight: 600,
    backgroundColor: 'white',
    borderRadius: 5,
    overflow: 'hidden',
  },
  center: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
