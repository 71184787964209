/**
 * PagesWrapper, as the name suggests, acts as a wrapper around our screens.
 * It applies the LinearGradient Background, based on the page's menu's background_colour, as set in the CMS.
 * If there is no colour, it will default to the BrandBlue.
 *
 * Arguments:
 * - children: any children to be rendered
 * - path: the path of the page that will be used to fetch adertising / content
 * - style: any style to apply to the outer View
 * - bgColor: hex code that will differ based on the menu of the page
 * - fetchContent: when true, we will fetch the content of the page - will need to pass true when <PageContent /> is on the parent component
 */

import React, {useState, useEffect, useContext, useRef, useMemo} from 'react';
import {
  View,
  useWindowDimensions,
  Pressable,
  Image,
  Platform,
} from 'react-native';

import AdvertisingBanner from '../components/advertising/AdvertisingBanner';
import AdvertisementSwiper from '../components/advertising/AdvertismentSwiper';
import LinearGradient from '../components/global/LinearGradient';
import Bookmaker from '../components/bookmakers/Bookmaker';
import Card from '../components/global/Card';
import Row from '../components/global/Row';
import Text from '../components/text/FnsText';
import Footer from '../components/navigation/Footer';
import AspectRatio from '../components/global/AspectRatio';

import {loadPageContent} from '../utils';
import AppContext from '../context/AppContext';
import Colours from '../assets/Colours';
import GlobalStyles from '../assets/styles';
import {useFocusEffect, useRoute} from '@react-navigation/native';

import {
  PagesWrapperProps, // eslint-disable-line @typescript-eslint/no-unused-vars
  AdvertisingObject, // eslint-disable-line @typescript-eslint/no-unused-vars
  leaderboardObj, // eslint-disable-line @typescript-eslint/no-unused-vars
} from '../@types/pages'; // eslint-disable-line @typescript-eslint/no-unused-vars

import BannerAd from '../components/global/BannerAd/BannerAd';
import HollywoodBetsBanner from '../components/advertising/HollywoodBetsBanner';
import {userIsInSa} from '../components/navigation/Utils';
import MetaData from '../utils/seo/MetaData';
import {Page} from '../utils/seo/metaDataText'; // eslint-disable-line @typescript-eslint/no-unused-vars


const ChevronLeftWhite = '/ChevronLeftWhite.svg';

const PagesWrapper: React.FC<PagesWrapperProps> = ({
  children,
  path,
  style,
  bgColor,
  productMenu,
}) => {
  const {width} = useWindowDimensions();
  const context = useContext(AppContext);
  const {
    bookmakers,
    currentStack,
    setCurrentStack,
    initialLoadComplete,
    setInitialLoadComplete,
    wrapperBackButtonFunction,
    setWrapperBackButtonFunction,
  } = context;

  const [loading, setLoading] = useState(true);
  const [displayBookmakers, setDisplayBookmakers] = useState(false);
  const [content, setContent] = useState('');
  const [advertising, setAdvertising] = useState({} as AdvertisingObject);
  const [headerImageURL, setHeaderImageURL] = useState<string>();
  const route = useRoute();

  // 18/01/2023 : Intl was making native Android crash
  // 02/04/2023 : Removed at SIS request, will keep in case needed again

  useEffect(() => {
    let cancelRequest = false;

    (async () => {
      const page = await loadPageContent(path);
      if (cancelRequest) {
        return;
      }

      if (page) {
        setContent(page.content);
        if (path !== 'home') setDisplayBookmakers(page.displayBookmakers);
        page.advertising.top_panel =
          page.advertising.top_panel &&
          page.advertising.top_panel.filter(
            (tp: leaderboardObj) => tp.small_image_url,
          );
        setAdvertising(page.advertising);
        setHeaderImageURL(page.headerImage);
      }

      setLoading(false);
    })();

    return () => {
      cancelRequest = true;
    };
  }, [path]);
  useEffect(() => {
    if (!currentStack && !initialLoadComplete) {
      setCurrentStack(productMenu);
      setInitialLoadComplete(true);
    }
  }, [
    setCurrentStack,
    setInitialLoadComplete,
    initialLoadComplete,
    productMenu,
    currentStack,
  ]);

  const viewRef: any = useRef(null);

  useFocusEffect(
    React.useCallback(() => {
      if (Platform.OS !== 'web') return;
      const contentContainerDiv = document.getElementById('content-container');
      if (!contentContainerDiv) {
        viewRef?.current?.setAttribute('id', 'content-container');
      }
      return () => {
        viewRef?.current?.removeAttribute('id');
      };
    }, []),
  );

  const PageMetaData = useMemo(() => {
    if (Platform.OS !== 'web') return null;
    return <MetaData page={path as Page} />;
  }, [path]);

  if (loading) {
    return null;
  }

  const background = bgColor || Colours.brandBlue;
  const backgroundColour = {backgroundColor: background};
  // We want to limit the banner ad to a size of 190px

  const isHomePage = route.name === 'Home';
  const isRespGambling = route.name === 'Responsible Gambling';
  const isWeb = Platform.OS === 'web';

  // const padTop = isHomePage ? '2%' : '0%';

  const paddingTopPct = {
    paddingTop:
      Platform.OS === 'web'
        ? isHomePage
          ? 'min(25%, 55px)'
          : 'min(40%, 190px)'
        : '2%',
  };

  // const stickyPos = {
  //   ...Platform.select({
  //     web: {
  //       position: 'sticky',
  //     },
  //   }),
  // };

  return (
    // <View style={{height: 'auto'}}>
    <View style={{flex: 1}} ref={viewRef} nativeID="content-container">
      {PageMetaData}
      <LinearGradient
        webGradientType={'background'}
        colours={[bgColor || Colours.brandBlue, Colours.black]}
        style={[
          GlobalStyles.alignCenter,
          GlobalStyles.flexGrow,
          GlobalStyles.paddingHorizontalMS,
          GlobalStyles.paddingBottomMS,
          GlobalStyles.paddingTopSM,
          GlobalStyles.fullWidth,
          backgroundColour,
          style,
          // {height: '100%'}
        ]}>
        {wrapperBackButtonFunction && (
          <View
            style={[
              GlobalStyles.fullWidth,
              GlobalStyles.paddingBottomSM,
              GlobalStyles.row,
            ]}>
            <Pressable
              style={[GlobalStyles.row, GlobalStyles.marginRightSM]}
              onPress={() => {
                wrapperBackButtonFunction();
                setWrapperBackButtonFunction(undefined);
              }}>
              <Image
                source={ChevronLeftWhite as any}
                style={[
                  GlobalStyles.iconHeightExtraSmall,
                  GlobalStyles.iconWidthExtraSmall,
                ]}
              />
              <Text style={GlobalStyles.whiteText}>{'Back'}</Text>
            </Pressable>
            <View style={GlobalStyles.flex} />
          </View>
        )}
        {isWeb && isHomePage ? (
          <View
            nativeID="top-banner-container"
            style={{
              marginBottom: 10,
            }}>
            {userIsInSa() ? ( // Dan => hardcode to false on 06/02/25 to remove HollywoodBetsBanner on nolans request
              <View style={{width: 1, height: 1}}>
                <HollywoodBetsBanner containerId="top-banner-container" />
              </View>
            ) : (
              <BannerAd
                richMedia={false}
                route={route.name}
                placementId="6565edfa5c0ed846d41b5845"
                style={{
                  minHeight: 280,
                  width: 336,
                }}
              />
            )}
          </View>
        ) : null}

        {(!isHomePage || !isWeb) &&
        advertising?.top_panel &&
        advertising.top_panel.length > 0 ? (
          <View
            style={[
              advertising.top_panel.length > 1 && GlobalStyles.marginHorizontal,
              GlobalStyles.fullWidth,
              GlobalStyles.borderRadiusSM,
              GlobalStyles.hiddenOverflow,
              !isHomePage && GlobalStyles.marginBottomSM,
              {height: 150},
            ]}>
            <AspectRatio style={paddingTopPct}>
              {advertising.top_panel.length > 1 ? (
                <View style={[GlobalStyles.fullWidth, GlobalStyles.fullHeight]}>
                  <AdvertisementSwiper
                    duration={advertising.duration}
                    advertisements={advertising.top_panel}
                    placementType={'top_panel'}
                  />
                </View>
              ) : (
                <AdvertisingBanner
                  advertisement={advertising.top_panel[0]}
                  placementType={'top_panel'}
                  style={GlobalStyles.fullWidth}
                />
              )}
            </AspectRatio>
          </View>
        ) : null}

        {/* <View
          style={{
            height: isHomePage ? 1800 : 1500,
            overflow: 'scroll',
            width: '100%',
          }}> */}
        {React.Children.map(children, child => {
          if (child) {
            return React.cloneElement(child, {
              content: content,
              headerImageURL: headerImageURL,
            });
          }
        })}
        {/* </View> */}

        {displayBookmakers && bookmakers && (
          <View
            style={[
              GlobalStyles.fullWidth,
              GlobalStyles.borderRadiusSM,
              GlobalStyles.hiddenOverflow,
              GlobalStyles.paddingVerticalSM,
              GlobalStyles.zindexNegativeOne,
            ]}>
            <Card
              noHeader={true}
              BodyContent={
                <>
                  <Text
                    size={'SmallMedium'}
                    decoration={'Bold'}
                    style={[
                      GlobalStyles.uppercase,
                      GlobalStyles.oceanBlueText,
                    ]}>
                    {'Partner Offers:'}
                  </Text>
                  <Row style={GlobalStyles.flexWrapWrap}>
                    {bookmakers.map(bookmaker => {
                      const bookmakerProps = {
                        ...bookmaker,
                        wrapperStyle:
                          width < 800
                            ? GlobalStyles.fullWidth
                            : GlobalStyles.flexBasisHalf,
                      };

                      return (
                        <Bookmaker
                          key={`bookmaker${bookmaker.name}`}
                          bookmaker={bookmakerProps}
                        />
                      );
                    })}
                  </Row>
                </>
              }
            />
          </View>
        )}

        <View style={[GlobalStyles.fullWidth, {bottom: 0}]}>
          <AspectRatio style={isHomePage ? {paddingTop: 300} : paddingTopPct}>
            {/* // <AdvertisingBanner
              // advertisement={advertising.bottom_panel}
              // placementType={'bottom_panel'}
              // style={GlobalStyles.fullWidth} */}

            {/* <BannerAd
              richMedia={true}
              route={route.name}
              placementId="6352aec26eb5c22c7ec50d1e"
              style={{display: 'none'}}
            /> */}
            {isRespGambling ? (
              <></>
            ) : isHomePage ? (
              <>
                <BannerAd
                  richMedia={false}
                  placementId={'6565ee1409ba2171d1441133'}
                />
                <BannerAd
                  richMedia={true}
                  placementId="626016a4df8d520b1c653a33"
                  style={{display: 'none'}}
                />
              </>
            ) : (
              <>
                <BannerAd
                  richMedia={true}
                  placementId="626016a4df8d520b1c653a33"
                  style={{display: 'none'}}
                />
                <BannerAd
                  richMedia={false}
                  placementId={'6352aec26eb5c22c7ec50d1e'}
                  style={{minHeight: '50px', minWidth: '320px'}}
                />
              </>
            )}
          </AspectRatio>
        </View>
        {Platform.OS !== 'web' ? <Footer /> : null}
      </LinearGradient>

      {Platform.OS === 'web' ? <Footer /> : null}
    </View>
  );
};

export default PagesWrapper;
