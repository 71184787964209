export type Page =
  | 'home'
  | '49s'
  | '49s/results'
  | 'lotto365'
  | 'lotto365/results'
  | 'irish-lotto-bet'
  | 'irish-lotto-bet/results'
  | 'virtual-horse-racing'
  | 'virtual-horse-racing/results'
  | 'virtual-greyhound-racing'
  | 'virtual-greyhound-racing/results';

type MetaData = {
  [key in Page]: {
    title: string;
    metaData: {
      name: string;
      content: string;
    }[];
  };
};

export const seoTitlesAndMetaData: MetaData = {
  home: {
    title: '49s.co.uk | The original 49s live lotto draw',
    metaData: [
      {
        name: 'description',
        content:
          '49s.co.uk | The original 49s live lotto draw. All your favourite number games including UK 49s, 39s & 15s. The official 49s app with Virtual Horse Racing and Virtual Greyhound Racing results, fixtures, form guides, and odds only on 49s.co.uk',
      },
      {
        name: 'keywords',
        content:
          '49s.co.uk, 49s live lotto draw, UK 49s, 49s, Virtual Horse Racing, Virtual Greyhound Racing results, 49s.co.uk',
      },
    ],
  },
  '49s': {
    title: ' 49s | The Original Live Draw | UK 49s',
    metaData: [
      {
        name: 'description',
        content:
          '49s The Original Live Draw and home of UK 49s. Live 49s Lottery, Bet365 Lottery, Irish Lottery, Virtual Horse Racing and Virtual Greyhound racing results. Get in the Original Live Draw on the official 49s UK app.',
      },
      {
        name: 'keywords',
        content:
          '49s, The Original Live Draw, UK 49s, 49s Lottery, Bet365 Lottery, Irish Lottery, Virtual Horse Racing, Virtual Greyhound racing results, Original Live Draw, 49s UK app',
      },
    ],
  },
  '49s/results': {
    title: '49s Results',
    metaData: [
      {
        name: 'description',
        content:
          'Get the latest 49s Results right here on the official Uk 49s app. Get all the UK 49s lunchtime results and the 49s UK Teatime results as they happen. If you missed any of the previous UK49s draws, get all the previous draw results on the 49s results page.',
      },
      {
        name: 'keywords',
        content:
          '49s Results, UK 49s, UK 49s lunchtime results, 49s UK Teatime results, UK49s, 49s results',
      },
    ],
  },
  lotto365: {
    title: 'Lotto 365',
    metaData: [
      {
        name: 'description',
        content:
          'Lotto 365 streams a live draw every 3 minutes, every day. The more you match, the more you win on Lotto 365.',
      },
      {
        name: 'keywords',
        content: 'Lotto 365',
      },
    ],
  },
  'lotto365/results': {
    title: 'Lotto 365 results',
    metaData: [
      {
        name: 'description',
        content:
          'Get the latest Lotto 365 results as well as past results right here on the Lotto 365 results page brought to you by 49s UK.',
      },
      {
        name: 'keywords',
        content: 'Lotto 365 results, 49s UK',
      },
    ],
  },
  'irish-lotto-bet': {
    title: 'Irish Lotto Bet',
    metaData: [
      {
        name: 'description',
        content:
          'Irish Lotto Bet, the official home of Irish Lottery betting. There are 3 draws on Wednesday and Saturday based on the Irish National Lottery. Irish Lotto Bet brought to you by 49s Lotto only on the official 49s UK app.',
      },
      {
        name: 'keywords',
        content: 'Irish Lotto Bet, 49s Lotto, 49s UK',
      },
    ],
  },
  'irish-lotto-bet/results': {
    title: 'Irish Lotto Bet Results',
    metaData: [
      {
        name: 'description',
        content:
          'View the latest Irish Lotto Bet results and check the winning numbers for all three draws, including Plus 1 and Plus 2 after every Wednesday and Saturday draw. If you missed any of the previous Irish Lotto Bet draws, get all the previous draw results on the Irish Lotto Bet results page.',
      },
      {
        name: 'keywords',
        content:
          'Irish Lotto Bet results, Irish Lotto Bet draws, Irish Lotto Bet results',
      },
    ],
  },
  'virtual-horse-racing': {
    title: 'Virtual Horse Racing',
    metaData: [
      {
        name: 'description',
        content:
          'Horse racing fans can place bets on Virtual Horse Racing with your favorite bookie, get all the latest results, current and future Virtual Horse races and betting odds on the 49s UK app.',
      },
      {
        name: 'keywords',
        content: 'Virtual Horse Racing, Virtual Horse, 49s UK',
      },
    ],
  },
  'virtual-horse-racing/results': {
    title: 'Virtual Horse Racing Results',
    metaData: [
      {
        name: 'description',
        content:
          'Get the latest Virtual Horse Racing results as they happen exclusively on the 49s UK Virtual Horse Racing results page.',
      },
      {
        name: 'keywords',
        content: 'Virtual Horse Racing, 49s UK,  Virtual Horse Racing Results',
      },
    ],
  },
  'virtual-greyhound-racing': {
    title: 'Virtual Greyhound Racing',
    metaData: [
      {
        name: 'description',
        content:
          'Greyhound racing fans can place bets on Virtual Greyhound Racing with your favorite bookie, get all the latest results, current and future Virtual Greyhound races and betting odds on the 49s UK app.',
      },
      {
        name: 'keywords',
        content: 'Virtual Greyhound Racing, Virtual Greyhound, 49s UK',
      },
    ],
  },
  'virtual-greyhound-racing/results': {
    title: 'Virtual Greyhound Racing Results',
    metaData: [
      {
        name: 'description',
        content:
          'Get the latest Virtual Greyhound Racing results as they happen exclusively on the 49s UK Virtual Greyhound Racing results page.',
      },
      {
        name: 'keywords',
        content:
          'Virtual Greyhound Racing, 49s UK,  Virtual Greyhound Racing results',
      },
    ],
  },
};
