import React, {useContext} from 'react';
import {Linking, Pressable, View, Image} from 'react-native';
import {useNavigation} from '@react-navigation/core';

import Text from '../text/FnsText';
import AgeWarning from '../global/AgeWarning';

import AppContext from '../../context/AppContext';
import GlobalStyles from '../../assets/styles';
import Layout from '../../assets/Layout';

import {ViewStyleProps} from '../../@types/global'; // eslint-disable-line @typescript-eslint/no-unused-vars

// images
import GambleAware from '../../Images/GambleAware.png';

import {Platform} from 'react-native';
import Socials from './Socials';

const platformIsWeb = Platform.OS === 'web';

const Footer: React.FC<ViewStyleProps> = ({style}) => {
  const navigation = useNavigation();
  const context = useContext(AppContext);
  const {navItems, platform, setCurrentStack} = context;
  const styles = platform === 'desktop' ? desktopStyles : mobileStyles;

  return (
    <View style={[GlobalStyles.footerPurpleBG, styles.container, style]}>
      <View style={[styles.content]}>
        <View
          style={[
            GlobalStyles.row,
            GlobalStyles.flexWrapWrap,
            GlobalStyles.paddingVerticalSM,
            styles.navLinksWrapper,
          ]}>
          {navItems.footer.pages.map((p, i) => (
            <View key={`${p.title}${i}`} style={GlobalStyles.flexBasisHalf}>
              <Pressable
                onPress={() => {
                  setCurrentStack(undefined);
                  navigation.navigate(p.title);
                }}>
                <Text
                  size={platform === 'desktop' ? 'SmallMedium' : 'Small'}
                  style={[
                    GlobalStyles.whiteText,
                    GlobalStyles.paddingBottomXS,
                    styles.navLinks,
                  ]}>
                  {p.display_title}
                </Text>
              </Pressable>
            </View>
          ))}
        </View>
        <View
          style={[
            GlobalStyles.row,
            GlobalStyles.flex,
            GlobalStyles.justifyCenter,
            GlobalStyles.alignCenter,
          ]}></View>
        <Socials onFooter />
      </View>

      <View
        style={[
          GlobalStyles.row,
          GlobalStyles.flexWrapWrap,
          GlobalStyles.paddingVerticalSM,
          GlobalStyles.iconHeightMedium,
          {justifyContent: 'center', alignItems: 'center', width: '100%'},
        ]}>
        <View style={[]}>
          <Pressable
            onPress={() => Linking.openURL('https://www.begambleaware.org/')}
            style={styles.gambleAwareWrapper}>
            <Image
              resizeMode={'stretch'}
              source={GambleAware as any}
              style={[GlobalStyles.fullHeight, {width: 150}]}
            />
          </Pressable>
        </View>
        <View style={{width: '10%'}} />
        <View style={[]}>
          <AgeWarning />
        </View>
      </View>
      <View
        style={[
          styles.footerInfo,
          GlobalStyles.paddingVerticalSM,
          GlobalStyles.paddingLeftL,
          GlobalStyles.paddingRightL,
        ]}>
        <Text size={'ExtraSmall'} style={GlobalStyles.midGrayFont}>
          {"49's Ltd is a company registered in England, number 10641214"}
        </Text>
        <Text
          onPress={() =>
            Linking.openURL(
              'https://www.gamblingcommission.gov.uk/public-register/business/detail/56709',
            )
          }
          size={'ExtraSmall'}
          style={GlobalStyles.midGrayFont}>
          {
            "49's LIMITED is licensed and regulated in Great Britain by the Gambling Commission under account number 56709"
          }
        </Text>
      </View>
    </View>
  );
};

const desktopStyles = {
  container: [
    GlobalStyles.alignCenter,
    GlobalStyles.fullWidth,
    GlobalStyles.paddingTop,
  ],
  content: [GlobalStyles.row, GlobalStyles.width90pct],
  navLinksWrapper: [GlobalStyles.flexBasisHalf],
  navLinks: [GlobalStyles.paddingLeft],
  gambleAwareWrapper: [
    GlobalStyles.justifyCenter,
    {height: Layout.iconSizeSmallMedium, minWidth: Layout.iconSizeExtraLarge},
  ],
  footerInfo: {marginBottom: 8},
};

const mobileStyles = {
  container: [
    {
      width: platformIsWeb ? 'auto' : '110%',
      height: platformIsWeb ? 'auto' : '100%',
    },
  ],
  content: [{alignItems: 'center', justifyContent: 'center'}],
  navLinksWrapper: [],
  navLinks: [GlobalStyles.paddingLeftL],
  gambleAwareWrapper: [{height: Layout.iconSizeSmallMedium}],
  footerInfo: {marginBottom: platformIsWeb ? 30 : 8},
};

export default Footer;
