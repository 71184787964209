/**
 * A file for commonly used styles exported as a stylesheet
 */

import {StyleSheet} from 'react-native';

import Colours from '../Colours';
import Layout from '../Layout';

import {StringIndex} from '../../@types/global'; // eslint-disable-line @typescript-eslint/no-unused-vars

const GlobalStyles = StyleSheet.create({
  ///////////// Text
  whiteText: {
    color: Colours.white,
  },
  blackText: {
    color: Colours.black,
  },
  oceanBlueText: {
    color: Colours.oceanBlue,
  },
  darkGrayFont: {
    color: Colours.gray900,
  },
  midGrayFont: {
    color: Colours.gray700,
  },
  midLightGrayFont: {
    color: Colours.gray600,
  },

  uppercase: {
    textTransform: 'uppercase',
  },
  lowercase: {
    textTransform: 'none',
  },

  ///////////// Text Align
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },

  ///////////// Flex Directions
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
  },

  ///////////// Padding
  paddingXS: {
    padding: Layout.spacerXS,
  },
  paddingSM: {
    padding: Layout.spacerSM,
  },
  paddingMS: {
    padding: Layout.spacerMS,
  },
  padding: {
    padding: Layout.spacer,
  },
  paddingrem03: {
    padding: Layout.rem03,
  },
  paddingrem05: {
    padding: Layout.rem05,
  },

  paddingVerticalXXS: {
    paddingVertical: Layout.spacerXXS,
  },
  paddingVerticalXS: {
    paddingVertical: Layout.spacerXS,
  },
  paddingVerticalSM: {
    paddingVertical: Layout.spacerSM,
  },
  paddingVerticalMS: {
    paddingVertical: Layout.spacerMS,
  },
  paddingVertical: {
    paddingVertical: Layout.spacer,
  },
  paddingVerticalRem05: {
    paddingVertical: Layout.rem05,
  },
  paddingVerticalRem1: {
    paddingVertical: Layout.rem1,
  },

  paddingHorizontalXXS: {
    paddingHorizontal: Layout.spacerXXS,
  },
  paddingHorizontalXS: {
    paddingHorizontal: Layout.spacerXS,
  },
  paddingHorizontalSM: {
    paddingHorizontal: Layout.spacerSM,
  },
  paddingHorizontalMS: {
    paddingHorizontal: Layout.spacerMS,
  },
  paddingHorizontal: {
    paddingHorizontal: Layout.spacer,
  },

  paddingTopXS: {
    paddingTop: Layout.spacerXS,
  },
  paddingTopSM: {
    paddingTop: Layout.spacerSM,
  },
  paddingTopMS: {
    paddingTop: Layout.spacerMS,
  },
  paddingTop: {
    paddingTop: Layout.spacer,
  },
  paddingTopL: {
    paddingTop: Layout.spacerL,
  },

  paddingTopLplusMS: {
    paddingTop: Layout.spacerL + Layout.spacerMS,
  },

  paddingTop25pct: {
    paddingTop: '25%',
  },
  paddingTop35pct: {
    paddingTop: '35%',
  },
  paddingToprem1: {
    paddingTop: Layout.rem1,
  },
  paddingToprem2: {
    paddingTop: Layout.rem2,
  },

  paddingBottomXXS: {
    paddingBottom: Layout.spacerXXS,
  },
  paddingBottomXS: {
    paddingBottom: Layout.spacerXS,
  },
  paddingBottomS: {
    paddingBottom: Layout.spacerS,
  },
  paddingBottomSM: {
    paddingBottom: Layout.spacerSM,
  },
  paddingBottomMS: {
    paddingBottom: Layout.spacerMS,
  },
  paddingBottom: {
    paddingBottom: Layout.spacer,
  },
  paddingBottomrem1: {
    paddingBottom: Layout.rem1,
  },
  paddingBottomrem3: {
    paddingBottom: Layout.rem3,
  },

  paddingLeftXS: {
    paddingLeft: Layout.spacerXS,
  },
  paddingLeftSM: {
    paddingLeft: Layout.spacerSM,
  },
  paddingLeftMS: {
    paddingLeft: Layout.spacerMS,
  },
  paddingLeft: {
    paddingLeft: Layout.spacer,
  },
  paddingLeftL: {
    paddingLeft: Layout.spacerL,
  },
  paddingLeftAdvertisingBannerM: {
    paddingLeft: Layout.advertisingBannerM,
  },
  paddingLeftrem1: {
    paddingLeft: Layout.rem1,
  },

  paddingRightXXS: {
    paddingRight: Layout.spacerXXS,
  },
  paddingRightXS: {
    paddingRight: Layout.spacerXS,
  },
  paddingRightSM: {
    paddingRight: Layout.spacerSM,
  },
  paddingRight: {
    paddingRight: Layout.spacer,
  },
  paddingRightL: {
    paddingRight: Layout.spacerL,
  },
  paddingRightrem05: {
    paddingRight: Layout.rem05,
  },
  paddingRightrem1: {
    paddingRight: Layout.rem1,
  },

  noPadding: {
    padding: 0,
  },
  noVerticalPadding: {
    paddingVertical: 0,
  },
  noHorizontalPadding: {
    paddingHorizontal: 0,
  },
  noPaddingLeft: {
    paddingLeft: 0,
  },
  noPaddingRight: {
    paddingRight: 0,
  },

  paddingLeft10rem: {
    paddingLeft: Layout.rem10,
  },

  paddingRight1pct: {
    paddingRight: '1%',
  },

  ///////////// Margin
  marginXXS: {
    margin: Layout.spacerXXS,
  },
  marginSM: {
    margin: Layout.spacerSM,
  },

  marginVerticalXS: {
    marginVertical: Layout.spacerXS,
  },
  marginVerticalSM: {
    marginVertical: Layout.spacerSM,
  },

  marginHorizontalXS: {
    marginHorizontal: Layout.spacerXS,
  },
  marginHorizontalXXS: {
    marginHorizontal: Layout.spacerXXS,
  },
  marginHorizontalSM: {
    marginHorizontal: Layout.spacerSM,
  },
  marginHorizontal: {
    marginHorizontal: Layout.spacer,
  },

  marginTopXXS: {
    marginTop: Layout.spacerXXS,
  },
  marginTopXS: {
    marginTop: Layout.spacerXS,
  },
  marginTopS: {
    marginTop: Layout.spacerS,
  },
  marginTopSM: {
    marginTop: Layout.spacerSM,
  },
  marginTopMS: {
    marginTop: Layout.spacerMS,
  },
  marginTopL: {
    marginTop: Layout.spacerL,
  },
  marginTopXXL: {
    marginTop: Layout.spacerXXL,
  },
  marginTopXXXL: {
    marginTop: Layout.spacerXXXL,
  },

  marginBottomXXS: {
    marginBottom: Layout.spacerXXS,
  },
  marginBottomXS: {
    marginBottom: Layout.spacerXS,
  },
  marginBottomS: {
    marginBottom: Layout.spacerS,
  },
  marginBottomSM: {
    marginBottom: Layout.spacerSM,
  },
  marginBottomMS: {
    marginBottom: Layout.spacerMS,
  },
  marginBottom: {
    marginBottom: Layout.spacer,
  },
  marginBottomL: {
    marginBottom: Layout.spacerL,
  },
  marginBottomRem1: {
    marginBottom: Layout.rem1,
  },

  marginRightXXS: {
    marginRight: Layout.spacerXXS,
  },
  marginRightXS: {
    marginRight: Layout.spacerXS,
  },
  marginRightSM: {
    marginRight: Layout.spacerSM,
  },
  marginRightMS: {
    marginRight: Layout.spacerMS,
  },
  marginRight: {
    marginRight: Layout.spacer,
  },
  marginRightXL: {
    marginRight: Layout.spacerXL,
  },

  marginLeftXXS: {
    marginLeft: Layout.spacerXXS,
  },
  marginLeftXS: {
    marginLeft: Layout.spacerXS,
  },
  marginLeftSM: {
    marginLeft: Layout.spacerSM,
  },
  marginLeft: {
    marginLeft: Layout.spacer,
  },
  marginLeftXL: {
    marginLeft: Layout.spacerXL,
  },

  negativeMarginHorizontal: {
    marginHorizontal: -Layout.spacer,
  },

  negativeMarginRightMS: {
    marginRight: -Layout.spacerMS,
  },
  negativeMarginRightML: {
    marginRight: -Layout.spacerML,
  },

  negativeMarginLeftXS: {
    marginLeft: -Layout.spacerXS,
  },
  negativeMarginLeftML: {
    marginLeft: -Layout.spacerML,
  },

  ///////////// Justify Content
  justifyCenter: {
    justifyContent: 'center',
  },
  justifyBetween: {
    justifyContent: 'space-between',
  },
  justifyEvenly: {
    justifyContent: 'space-evenly',
  },
  justifyEnd: {
    justifyContent: 'flex-end',
  },
  justifyStart: {
    justifyContent: 'flex-start',
  },

  ///////////// Align Items
  alignStart: {
    alignItems: 'flex-start',
  },
  alignCenter: {
    alignItems: 'center',
  },
  alignEnd: {
    alignItems: 'flex-end',
  },

  ///////////// Align Self
  alignSelfEnd: {
    alignSelf: 'flex-end',
  },
  alignSelfStart: {
    alignSelf: 'flex-start',
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  alignSelfStretch: {
    alignSelf: 'stretch',
  },

  ///////////// Backgrounds
  bodyBG: {
    backgroundColor: Colours.background,
  },
  transparentBG: {
    backgroundColor: 'transparent',
  },
  blueBG: {
    backgroundColor: Colours.brandBlue,
  },
  blackBG: {
    backgroundColor: Colours.black,
  },
  veryLightGrayBG: {
    backgroundColor: Colours.gray200,
  },
  lightGrayBG: {
    backgroundColor: Colours.gray300,
  },
  midLightGrayBG: {
    backgroundColor: Colours.gray400,
  },
  midGrayBG: {
    backgroundColor: Colours.gray500,
  },
  darkGrayBG: {
    backgroundColor: Colours.gray600,
  },
  twitterBlueBG: {
    backgroundColor: Colours.twitterBG,
  },
  oceanBlueBG: {
    backgroundColor: Colours.oceanBlue,
  },
  footerPurpleBG: {
    backgroundColor: Colours.footerPurple,
  },
  opaqueBGOneTenth: {
    backgroundColor: 'rgba(33, 37, 41, 0.1)',
  },
  opaqueBGOneHalf: {
    backgroundColor: 'rgba(33, 37, 41, 0.5)',
  },

  ///////////// Flex
  flexGrow: {
    flexGrow: 1,
  },

  flex: {
    flex: 1,
  },
  flexhalf: {
    flex: 0.5,
  },
  flexTwoThirds: {
    flex: 0.66,
  },
  flexThird: {
    flex: 0.33,
  },

  flexBasis33pct: {
    flexBasis: '32%',
  },
  flexBasis45pct: {
    flexBasis: '45%',
  },
  flexBasisHalf: {
    flexBasis: '49%',
  },

  flexWrapWrap: {
    flexWrap: 'wrap',
  },
  flexWrapNoWrap: {
    flexWrap: 'nowrap',
  },

  ///////////// Height
  fullHeight: {
    height: '100%',
  },
  height75pct: {
    height: '75%',
  },
  halfHeight: {
    height: '50%',
  },

  hardHeight: {
    height: 600,
  },

  desktopBannerHeight: {
    height: '12rem',
  },

  heightrem2: {
    height: 32,
  },
  heightrem5: {
    height: 80,
  },
  heightNarrow: {
    height: Layout.borderWidthNarrow,
  },

  ///////////// Width
  fullWidth: {
    width: '100%',
  },
  width95pct: {
    width: '95%',
  },
  width90pct: {
    width: '90%',
  },
  width80pct: {
    width: '80%',
  },
  width70pct: {
    width: '70%',
  },
  width60pct: {
    width: '60%',
  },
  halfWidth: {
    width: '50%',
  },
  width40pct: {
    width: '40%',
  },
  quarterWidth: {
    width: '25%',
  },
  width20pct: {
    width: '20%',
  },

  sliderWidth: {
    width: '105%',
  },
  imageOverflowWidth: {
    width: '120%',
  },

  hardWidth: {
    width: 160,
  },

  widthrem6: {
    width: '6rem',
  },

  narrowWidth: {
    width: Layout.borderWidthNarrow,
  },

  ///////////// Border Radius
  borderRadiusSM: {
    borderRadius: Layout.borderRadiusSM,
  },
  borderRadiusLG: {
    borderRadius: Layout.borderRadiusLG,
  },
  borderRadiusCircle: {
    borderRadius: Layout.iconSizeExtraExtraLarge,
  },

  borderRadiusBottomSM: {
    borderBottomLeftRadius: Layout.borderRadiusSM,
    borderBottomRightRadius: Layout.borderRadiusSM,
  },

  borderRadiusTopSM: {
    borderTopLeftRadius: Layout.borderRadiusSM,
    borderTopRightRadius: Layout.borderRadiusSM,
  },

  borderRadiusLeftSM: {
    borderTopLeftRadius: Layout.borderRadiusSM,
    borderBottomLeftRadius: Layout.borderRadiusSM,
  },

  ///////////// Border Width
  noBorder: {
    borderWidth: 0,
  },

  borderWidthNarrow: {
    borderWidth: Layout.borderWidthNarrow,
  },
  borderWidthSeparator: {
    borderWidth: Layout.borderWidthSeparator,
  },

  borderHorizontalmNarrow: {
    borderLeftWidth: Layout.borderWidthNarrow,
    borderRightWidth: Layout.borderWidthNarrow,
  },

  borderBottomNarrow: {
    borderBottomWidth: Layout.borderWidthNarrow,
  },

  borderTopNarrow: {
    borderTopWidth: Layout.borderWidthNarrow,
  },

  borderRightSeparator: {
    borderRightWidth: Layout.borderWidthSeparator,
  },

  borderLeftNarrow: {
    borderLeftWidth: Layout.borderWidthNarrow,
  },

  ///////////// Border Color
  borderBlack: {
    borderColor: Colours.black,
  },
  borderWhite: {
    borderColor: Colours.white,
  },
  borderGray: {
    borderColor: Colours.gray500,
  },
  borderTransparent: {
    borderColor: 'transparent',
  },

  borderTopGray: {
    borderTopColor: Colours.gray700,
  },
  borderBottomWhite: {
    borderBottomColor: Colours.white,
  },
  borderBottomMidGray: {
    borderBottomColor: Colours.gray600,
  },

  ///////////// SVGS / Images
  iconSizeSmallMedium: {
    height: Layout.iconSizeSmallMedium,
    width: Layout.iconSizeSmallMedium,
  },
  iconSizeMedium: {
    height: Layout.iconSizeMedium,
    width: Layout.iconSizeMedium,
  },

  iconSizeSmall: {
    height: Layout.iconSizeSmall,
    width: Layout.iconSizeSmall,
  },

  ///////////// Icon Widths
  iconWidthXXXSmall: {
    width: Layout.iconSizeXXXSmall,
  },
  iconWidthExtraSmall: {
    width: Layout.iconSizeExtraSmall,
  },
  iconWidthSmall: {
    width: Layout.iconSizeSmall,
  },
  iconWidthSmallMedium: {
    width: Layout.iconSizeSmallMedium,
  },
  iconWidthMediumSmall: {
    width: Layout.iconSizeMediumSmall,
  },
  iconWidthMedium: {
    width: Layout.iconSizeMedium,
  },
  iconWidthMediumLarge: {
    width: Layout.iconSizeMediumLarge,
  },
  iconWidthExtraLarge: {
    width: Layout.iconSizeExtraLarge,
  },
  iconWidthExtraExtraLarge: {
    width: Layout.iconSizeExtraExtraLarge,
  },

  advertisingBannerWidthM: {
    width: Layout.advertisingBannerM,
  },
  advertisingBannerWidthXL: {
    width: Layout.advertisingBannerXL,
  },

  minWidthIconSizeMediumLarge: {
    minWidth: Layout.iconSizeMediumLarge,
  },
  minWidthIconSizeExtraLarge: {
    minWidth: Layout.iconSizeExtraLarge,
  },
  minWidthrem16: {
    minWidth: Layout.rem16,
  },

  ///////////// Icon Heights
  iconHeightXXXSmall: {
    height: Layout.iconSizeXXXSmall,
  },
  iconHeightExtraSmall: {
    height: Layout.iconSizeExtraSmall,
  },
  iconHeightSmall: {
    height: Layout.iconSizeSmall,
  },
  iconHeightSmallMedium: {
    height: Layout.iconSizeSmallMedium,
  },
  iconHeightMedium: {
    height: Layout.iconSizeMedium,
  },
  iconHeightExtraExtraLarge: {
    height: Layout.iconSizeExtraExtraLarge,
  },

  advertisingBannerHeightS: {
    height: Layout.advertisingBannerS,
  },
  advertisingBannerHeightM: {
    height: Layout.advertisingBannerM,
  },
  advertisingBannerHeightXL: {
    height: Layout.advertisingBannerXL,
  },
  advertisingBannerHeightXXL: {
    height: Layout.advertisingBannerXXL,
  },

  layoutSpacerSMHeight: {
    height: Layout.spacerSM,
  },

  minHeightIconSizeMedium: {
    minHeight: Layout.iconSizeMedium,
  },

  ///////////// Positions
  top20: {
    top: 20,
  },

  bottom0: {
    bottom: 0,
  },

  right0: {
    right: 0,
  },

  ///////////// Other
  hiddenOverflow: {
    overflow: 'hidden',
  },

  absolute: {
    position: 'absolute',
  },
  relative: {
    position: 'relative',
  },

  halfOpacity: {
    opacity: 0.5,
  },

  divider: {
    width: Layout.borderWidthNarrow,
  },

  maxHeight10rem: {
    maxHeight: Layout.rem10,
  },
  maxWidth10rem: {
    maxWidth: Layout.rem10,
  },
  maxWidth20rem: {
    maxWidth: Layout.rem20,
  },
  maxWidth30rem: {
    maxWidth: Layout.rem30,
  },
  maxWidth60rem: {
    maxWidth: Layout.rem60,
  },

  zindexNegativeOne: {
    zIndex: -1,
  },
}) as StringIndex;

export default GlobalStyles;
