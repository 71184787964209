import {Platform} from 'react-native';
import React from 'react';
import {Helmet} from 'react-helmet-async';
import {seoTitlesAndMetaData, Page} from './metaDataText'; // eslint-disable-line @typescript-eslint/no-unused-vars

type Props = {
  page: Page;
};

type MetaData = {
  title: string;
  metaData: {
    name: string;
    content: string;
  }[];
};

const MetaData = ({page}: Props) => {
  if (Platform.OS !== 'web') return null;

  const PageMetaData = seoTitlesAndMetaData[page]; // not destructuring to avoid undefined error

  if (!PageMetaData?.title || !PageMetaData?.metaData)
    return (
      <Helmet>
        {' '}
        <meta
          name="description"
          content="The official 49's app with results, fixtures, form guides, and odds."
        />
      </Helmet>
    );

  return (
    <Helmet>
      {PageMetaData?.title ? <title>{PageMetaData?.title}</title> : null}
      {PageMetaData?.metaData?.map((meta, index) => {
        if (!meta.name || !meta.content)
          return (
            <meta
              name="description"
              content="The official 49's app with results, fixtures, form guides, and odds."
            />
          );
        return <meta key={index} name={meta.name} content={meta.content} />;
      })}
    </Helmet>
  );
};

export default MetaData;
