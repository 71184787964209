import {View} from 'react-native';
import React, {useContext} from 'react';
import GlobalStyles from '../../assets/styles';
import Colours from '../../assets/Colours';
import Card from '../../components/global/Card';
import {allProducts} from '../../products';
import PlacingRow from '../../components/racing/results/PlacingRow';
import ProductButtons from './ProductButtons';
import Text from '../../components/text/FnsText';
import DateAndTime from 'date-and-time';
import Ordinal from 'date-and-time/plugin/ordinal';
import {StringIndex} from '../../@types/global'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {imageFromAPI} from '../../utils';
import FNImage from '../../components/global/FnsImage';
import AppContext from '../../context/AppContext';

DateAndTime.plugin(Ordinal);

type Props = {
  item: any;
  screenType?: string;
};

const RacingCarouselItem = ({item, screenType}: Props) => {
  const product = allProducts.find(p => p?.code === item?.product_code);
  const {navItems} = useContext(AppContext);

  const textColor = {
    color: product?.code === 'HR' ? Colours.white : Colours.black,
  };

  const textSizeMap = {
    desktop: 'Medium',
    tablet: 'Medium',
    phone: 'Small',
  } as StringIndex;

  const phoneStyles = {
    position: 'absolute',
    height: '100%',
    // left: 0,
    // width: '100%',
    // right: 0,
    flex: 1,
    alignItems: 'center',

    backgroundColor: Colours[product?.code],
  } as StringIndex;

  const otherStyles = {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '42%',
    maxWidth: 350,
    backgroundColor: Colours[product?.code],
    borderRadius: 5,
  } as StringIndex;

  return (
    <View style={screenType === 'phone' ? phoneStyles : otherStyles}>
      <Card
        noHeader={true}
        bodyStyle={[GlobalStyles.noPadding, GlobalStyles.marginBottomS]}
        BodyContent={
          <View
            style={[
              GlobalStyles.flex,
              GlobalStyles.alignSelfStretch,
              GlobalStyles.row,
              GlobalStyles.flexWrapWrap,
              {backgroundColor: Colours[product?.code]},
            ]}>
            <View
              style={[
                GlobalStyles.paddingLeftSM,
                GlobalStyles.paddingRightSM,
                {width: '100%'},
              ]}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  position: 'relative',
                  width: '100%',
                }}>
                <Text
                  style={[textColor]}
                  numberOfLines={1}
                  size={textSizeMap[screenType]}>
                  {DateAndTime.format(
                    new Date(item.event.scheduled_time),
                    'dddd DDD MMMM',
                  )}
                </Text>
                {screenType === 'phone' ? (
                  <FNImage
                    resizeMode={'contain'}
                    imageURL={imageFromAPI(
                      navItems[`secondary/${product?.path}`]?.logo_image_url,
                      {
                        width: 200,
                        height: 100,
                      },
                    )}
                    style={{
                      height: 100,
                      width: 200,
                      position: 'absolute',
                      right: '-15%',
                      top: '-130%',
                    }}
                  />
                ) : null}
              </View>
              <Text
                decoration={'Bold'}
                size={textSizeMap[screenType]}
                style={[GlobalStyles.uppercase, textColor]}>
                {item.event.venue_name.match(/[A-Z][a-z]+/g)?.join(' ')}
              </Text>

              <View style={[GlobalStyles.row, GlobalStyles.alignCenter]}>
                <Text style={[textColor]} decoration={'Bold'} size={'Medium'}>
                  {`R${item.event?.number} `}
                </Text>
                <Text style={[textColor]} size={textSizeMap[screenType]}>
                  {DateAndTime.format(
                    new Date(item.event?.scheduled_time || ''),
                    'HH:mm',
                  )}
                </Text>
              </View>
            </View>
            {item.event.runners
              .sort((a, b) => a.finish_position - b.finish_position)
              .map((runner, index) => {
                return (
                  <PlacingRow
                    key={`Runner${item.event.code}${index}`}
                    runner={runner}
                    bgColor={Colours[product?.path]}
                    size={textSizeMap[screenType]}
                  />
                );
              })}
            <View
              style={{
                width: '100%',
                height: '100%',
                alignItems: 'center',
                paddingTop: item?.runners?.length === 4 ? 0 : 10,
                zIndex: 100,
              }}>
              <ProductButtons product={product} />
            </View>
          </View>
        }
      />
    </View>
  );
};

export default RacingCarouselItem;
