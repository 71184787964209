/**
 * File to hold all static pages that exist
 */
import {ComponentMappingInterface} from '../../@types/navigation'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {lazy} from 'react';

const TheFount = lazy(() => import('../../pages/fount'));
// import TheFount from '../../pages/fount';
const Homepage = lazy(() => import('../../pages/home'));
// import Homepage from '../../pages/home';

// import ContactUsPage from '../../pages/footer/ContactUs';
const ContactUsPage = lazy(() => import('../../pages/footer/ContactUs'));
// import HelpPage from '../../pages/footer/Help';
const HelpPage = lazy(() => import('../../pages/footer/Help'));

///////////// FNS (49s) Stack

const FnsHome = lazy(() => import('../../pages/stacks/fns/Index'));
const FnsResults = lazy(() => import('../../pages/stacks/fns/Results'));
const FnsHotBalls = lazy(() => import('../../pages/stacks/fns/fount/HotBalls'));
const FnsColdBalls = lazy(
  () => import('../../pages/stacks/fns/fount/ColdBalls'),
);
const FnsPresenters = lazy(
  () => import('../../pages/stacks/fns/presenters/Index'),
);
const FnsWhatYouCanWin = lazy(() => import('../../pages/stacks/fns/Winnings'));
const FnsLuckyDip = lazy(() => import('../../pages/stacks/fns/LuckyDip'));
const Blogs = lazy(() => import('../../components/blogs/Blogs'));
const BlogPost = lazy(() => import('../../components/blogs/BlogPost'));

///////////// Irish Lotto Bet
// import IrishLottoBetHome from '../../pages/stacks/irishLottoBet/Index';
// import IrishLottoBetResults from '../../pages/stacks/irishLottoBet/Results';
// import IrishLottoBetHotBalls from '../../pages/stacks/irishLottoBet/fount/HotBalls';
// import IrishLottoBetColdBalls from '../../pages/stacks/irishLottoBet/fount/ColdBalls';
// import IrishLottoBetWhatYouCanWin from '../../pages/stacks/irishLottoBet/Winnings';
// import IrishLottoBetLuckyDip from '../../pages/stacks/irishLottoBet/LuckyDip';
const IrishLottoBetHome = lazy(
  () => import('../../pages/stacks/irishLottoBet/Index'),
);
const IrishLottoBetResults = lazy(
  () => import('../../pages/stacks/irishLottoBet/Results'),
);
const IrishLottoBetHotBalls = lazy(
  () => import('../../pages/stacks/irishLottoBet/fount/HotBalls'),
);
const IrishLottoBetColdBalls = lazy(
  () => import('../../pages/stacks/irishLottoBet/fount/ColdBalls'),
);
const IrishLottoBetWhatYouCanWin = lazy(
  () => import('../../pages/stacks/irishLottoBet/Winnings'),
);
const IrishLottoBetLuckyDip = lazy(
  () => import('../../pages/stacks/irishLottoBet/LuckyDip'),
);

///////////// TNS (39s) Stack
// import TnsHome from '../../pages/stacks/tns/Index';
// import TnsResults from '../../pages/stacks/tns/Results';
// import TnsHotBalls from '../../pages/stacks/tns/fount/HotBalls';
// import TnsColdBalls from '../../pages/stacks/tns/fount/ColdBalls';
// import TnsWhatYouCanWin from '../../pages/stacks/tns/Winnings';
// import TnsLuckyDip from '../../pages/stacks/tns/LuckyDip';
const TnsHome = lazy(() => import('../../pages/stacks/tns/Index'));
const TnsResults = lazy(() => import('../../pages/stacks/tns/Results'));
const TnsHotBalls = lazy(() => import('../../pages/stacks/tns/fount/HotBalls'));
const TnsColdBalls = lazy(
  () => import('../../pages/stacks/tns/fount/ColdBalls'),
);
const TnsWhatYouCanWin = lazy(() => import('../../pages/stacks/tns/Winnings'));
const TnsLuckyDip = lazy(() => import('../../pages/stacks/tns/LuckyDip'));

///////////// F15s (15s) Stack
// import F15sHome from '../../pages/stacks/f15s/Index';
// import F15sResults from '../../pages/stacks/f15s/Results';
// import F15sHotBalls from '../../pages/stacks/f15s/fount/HotBalls';
// import F15sColdBalls from '../../pages/stacks/f15s/fount/ColdBalls';
// import F15sWhatYouCanWin from '../../pages/stacks/f15s/Winnings';
// import F15sLuckyDip from '../../pages/stacks/f15s/LuckyDip';
const F15sHome = lazy(() => import('../../pages/stacks/f15s/Index'));
const F15sResults = lazy(() => import('../../pages/stacks/f15s/Results'));
const F15sHotBalls = lazy(
  () => import('../../pages/stacks/f15s/fount/HotBalls'),
);
const F15sColdBalls = lazy(
  () => import('../../pages/stacks/f15s/fount/ColdBalls'),
);
const F15sWhatYouCanWin = lazy(
  () => import('../../pages/stacks/f15s/Winnings'),
);
const F15sLuckyDip = lazy(() => import('../../pages/stacks/f15s/LuckyDip'));

///////////// lotto365 Stack (365) Stack
// import Lotto365 from '../../pages/stacks/bet365/Index';
// import Lotto365Results from '../../pages/stacks/bet365/Results';
// import Lotto365HotBalls from '../../pages/stacks/bet365/fount/HotBalls';
// import Lotto365ColdBalls from '../../pages/stacks/bet365/fount/ColdBalls';
// import Lotto365WhatYouCanWin from '../../pages/stacks/bet365/Winnings';
// import Lotto365LuckyDip from '../../pages/stacks/bet365/LuckyDip';
const Lotto365 = lazy(() => import('../../pages/stacks/bet365/Index'));
const Lotto365Results = lazy(() => import('../../pages/stacks/bet365/Results'));
const Lotto365HotBalls = lazy(
  () => import('../../pages/stacks/bet365/fount/HotBalls'),
);
const Lotto365ColdBalls = lazy(
  () => import('../../pages/stacks/bet365/fount/ColdBalls'),
);
const Lotto365WhatYouCanWin = lazy(
  () => import('../../pages/stacks/bet365/Winnings'),
);
const Lotto365LuckyDip = lazy(
  () => import('../../pages/stacks/bet365/LuckyDip'),
);

///////////// Virtual Horse Racing
// import VirtualHorseRacing from '../../pages/stacks/virtualHorseRacing/Index';
// import VirtualHorseRacingResults from '../../pages/stacks/virtualHorseRacing/Results';
const VirtualHorseRacing = lazy(
  () => import('../../pages/stacks/virtualHorseRacing/Index'),
);
const VirtualHorseRacingResults = lazy(
  () => import('../../pages/stacks/virtualHorseRacing/Results'),
);

///////////// Virtual Greyhound Racing
// import VirtualGreyhoundRacing from '../../pages/stacks/virtualGreyhoundRacing/Index';
// import VirtualGreyhoundRacingResults from '../../pages/stacks/virtualGreyhoundRacing/Results';
const VirtualGreyhoundRacing = lazy(
  () => import('../../pages/stacks/virtualGreyhoundRacing/Index'),
);
const VirtualGreyhoundRacingResults = lazy(
  () => import('../../pages/stacks/virtualGreyhoundRacing/Results'),
);

const Mapping = {
  'the-fount': TheFount,
  home: Homepage,
  contact: ContactUsPage,
  help: HelpPage,

  '49s': FnsHome,
  '49s/results': FnsResults,
  '49s/hot-balls': FnsHotBalls,
  '49s/cold-balls': FnsColdBalls,
  '49s/presenters': FnsPresenters,
  '49s/what-you-can-win': FnsWhatYouCanWin,
  '49s/lucky-dip': FnsLuckyDip,
  '49s/blogs': Blogs,
  '49s/offair/:title/:id': BlogPost,

  'irish-lotto-bet': IrishLottoBetHome,
  'irish-lotto-bet/results': IrishLottoBetResults,
  'irish-lotto-bet/hot-balls': IrishLottoBetHotBalls,
  'irish-lotto-bet/cold-balls': IrishLottoBetColdBalls,
  'irish-lotto-bet/what-you-can-win': IrishLottoBetWhatYouCanWin,
  'irish-lotto-bet/lucky-dip': IrishLottoBetLuckyDip,

  '39s': TnsHome,
  '39s/results': TnsResults,
  '39s/hot-balls': TnsHotBalls,
  '39s/cold-balls': TnsColdBalls,
  '39s/what-you-can-win': TnsWhatYouCanWin,
  '39s/lucky-dip': TnsLuckyDip,

  '15s': F15sHome,
  '15s/results': F15sResults,
  '15s/hot-balls': F15sHotBalls,
  '15s/cold-balls': F15sColdBalls,
  '15s/what-you-can-win': F15sWhatYouCanWin,
  '15s/lucky-dip': F15sLuckyDip,

  lotto365: Lotto365,
  'lotto365/results': Lotto365Results,
  'lotto365/hot-balls': Lotto365HotBalls,
  'lotto365/cold-balls': Lotto365ColdBalls,
  'lotto365/what-you-can-win': Lotto365WhatYouCanWin,
  'lotto365/lucky-dip': Lotto365LuckyDip,

  'virtual-horse-racing': VirtualHorseRacing,
  'virtual-horse-racing/results': VirtualHorseRacingResults,

  'virtual-greyhound-racing': VirtualGreyhoundRacing,
  'virtual-greyhound-racing/results': VirtualGreyhoundRacingResults,
} as ComponentMappingInterface;

export default Mapping;
