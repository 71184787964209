import {StyleSheet, View} from 'react-native';
import React, {useContext} from 'react';
import AppContext from '../../context/AppContext';
import {useNavigation} from '@react-navigation/native';
import Button from '../../components/global/Button';
import GlobalStyles from '../../assets/styles';
import Colours from '../../assets/Colours';

type Props = {
  product: any;
  onCarousel?: boolean;
};

const ProductButtons = ({product, onCarousel = false}: Props) => {
  const context = useContext(AppContext);
  const {navItems, setCurrentStack, platform} = context;
  const isDesktop = platform === 'desktop';
  const navigation = useNavigation();

  const productCode = product?.code;

  const previousResults = navItems[`secondary/${product?.path}`]?.pages.find(
    p => p.path === `${product?.path}/results`,
  )?.title;
  const betNow = navItems[`secondary/${product?.path}`]?.pages.find(
    p => p.path === `${product?.path}/bet-now`,
  )?.title;

  const bg = Colours[product?.code];

  return (
    <View
      style={[
        styles.buttonContainer,
        {
          justifyContent:
            productCode == 49 && onCarousel ? 'flex-end' : 'space-evenly',
        },
      ]}>
      <Button
        onpress={() => {
          setCurrentStack(product?.path);
          navigation.navigate(previousResults || '');
        }}
        text={'Results'}
        textDecoration={'Bold'}
        textSize={isDesktop ? 'Small' : 'ExtraSmall'}
        inverse={true}
        textStyle={[
          GlobalStyles.whiteText,
          // isDesktop && GlobalStyles.paddingHorizontal,
        ]}
        containerStyles={{
          borderWidth: 1,
          borderColor: 'black',
          backgroundColor: bg,
        }}
      />
      <View style={{width: '1%'}} />
      <Button
        onpress={() => {
          setCurrentStack(product?.path);
          navigation.navigate(betNow || '');
        }}
        text={'Bet Now'}
        textDecoration={'Bold'}
        textSize={isDesktop ? 'Small' : 'ExtraSmall'}
        containerStyles={[product?.code === '36' && {borderColor: 'white'}]}
      />
    </View>
  );
};

export default ProductButtons;

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    paddingVertical: 5,
    // paddingHorizontal: 10,
    // justifyContent: 'space-evenly',
    alignItems: 'center',
    maxHeight: 50,
    width: '100%',
    top: '-1%',
  },
});
