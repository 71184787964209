import React from 'react'
import {View, StyleProp, ViewStyle} from 'react-native' // eslint-disable-line @typescript-eslint/no-unused-vars
import {StringIndex} from '../../../@types/global' // eslint-disable-line @typescript-eslint/no-unused-vars

interface WebGradientProps {
    style: any,
    colours: any,
    children: any,
    webGradientType: any,
    onLayout: any
}

const webGradientMapping = ({
    offCanvasMenu: (colours: [string, string]) => `linear-gradient(58deg, ${colours[0]} 0%, ${colours[1]} 64%`,
    lozenge: (colours: [string, string]) => `linear-gradient(355deg, ${colours[0]} 56%, ${colours[1]} 57%)`,
    background: (colours: [string]) => `linear-gradient(${colours[0]} 0%, rgb(0, 0, 0, 0.05) 1%)`,
    webBackground: () => 'linear-gradient(rgb(0, 0, 0, 0.33), rgb(0, 0, 0, 0.05))',
    webBreadCrumb: () => 'linear-gradient(rgb(0, 0, 0, 0.33), rgb(0, 0, 0, 0.33))',
    mainNav: (colours: [string, string]) => `linear-gradient(${colours[0]} 0%, ${colours[1]} 100%`,
  }) as StringIndex


const WebGradient: React.FC<WebGradientProps> = ({colours, style, children, webGradientType,onLayout}) => {
    const webStyle = {
        backgroundImage: webGradientType ? webGradientMapping[webGradientType](colours || undefined) : `linear-gradient(${colours.map((c:any) => c)})`,
      } as StyleProp<ViewStyle>
    return (
    <View style={[webStyle, style]} onLayout={onLayout}>
        {children}
    </View>
    )
}

export default WebGradient
