import React, {useContext} from 'react';
import {View, Pressable, useWindowDimensions, Image} from 'react-native';
import {useRoute} from '@react-navigation/core';

import APIImage from '../global/FnsImage/';
import ProductLogoSlider from './ProductLogoSlider';
import LinearGradient from '../global/LinearGradient';
import BreadCrumbItem from '../../components/navigation/BreadCrumbItem';

import AppContext from '../../context/AppContext';
import {imageFromAPI, whiteOrBlackText} from '../../utils';
import {reset} from '../../utils/navigation';
import Colours from '../../assets/Colours';
import GlobalStyles from '../../assets/styles/';
// import Layout from '../../assets/Layout';

import {NavProps} from '../../@types/navigation'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {LinkObj} from '../../@types/global'; // eslint-disable-line @typescript-eslint/no-unused-vars
import Socials from './Socials';

const Home = '/Home.svg';
const HomeWhite = '/HomeWhite.svg';
const Hamburger = '/Hamburger.svg';

const DesktopMainNav: React.FC<NavProps> = ({
  currentPage,
  navigation,
  bgColor,
}) => {
  const {width} = useWindowDimensions();
  const {name: routeName} = useRoute();

  const context = useContext(AppContext);
  const {navItems, setCurrentStack, currentStack} = context;
  const mainLogo = navItems.products.logo_image_url;
  const productMenu = Object.keys(navItems).find(n => n.includes(currentStack));
  // const [logoHeight, setLogoHeight] = React.useState(0);
  const [logoWidth, setLogoWidth] = React.useState(0);

  ///////////// Styles
  const background = bgColor || navItems.products.colors.background_color;
  const backgroundColor = {backgroundColor: background};
  const subNavStyles =
    productMenu && navItems[productMenu].pages.length === 0
      ? GlobalStyles.paddingLeft10rem
      : GlobalStyles.justifyCenter;

  ///////////// Helper functions
  const resetNav = () => {
    setCurrentStack(undefined);
    reset(navigation);
  };

  return (
    <LinearGradient
      webGradientType={'mainNav'}
      colours={[Colours.brandBlue, Colours.brandDarkBlue]}
      style={[
        backgroundColor,
        GlobalStyles.fullWidth,
        GlobalStyles.paddingTop,
      ]}>
      <View style={GlobalStyles.row}>
        <Pressable
          style={[GlobalStyles.paddingLeftL, GlobalStyles.paddingTopMS]}
          onPress={() => navigation.openDrawer()}>
          <View
            style={[GlobalStyles.iconWidthSmall, GlobalStyles.iconHeightSmall]}>
            <Image
              source={Hamburger as any}
              style={[GlobalStyles.fullHeight, GlobalStyles.fullWidth]}
            />
          </View>
        </Pressable>
        <View
          onLayout={e => {
            // setLogoHeight(e.nativeEvent.layout.height);
            setLogoWidth(e.nativeEvent.layout.width);
          }}
          style={[GlobalStyles.flex, GlobalStyles.alignCenter]}>
          <Pressable
            onPress={() =>
              currentPage.name === 'Home' ? undefined : resetNav()
            }
            style={[
              GlobalStyles.fullHeight,
              currentPage.name === 'Home'
                ? undefined
                : GlobalStyles.paddingBottomMS,
            ]}>
            <APIImage
              resizeMode={'contain'}
              imageURL={imageFromAPI(mainLogo, {
                width: 200,
                height: 150,
              })}
              style={{width: logoWidth, maxWidth: 200}}
              loading="eager"
            />
          </Pressable>
        </View>
        <View style={[{marginTop: '4%'}]}>
          <ProductLogoSlider
            onPress={(page: LinkObj) => {
              context.setCurrentStack(page.display_path);
              navigation.navigate(page.title);
            }}
            bottomTriangle={false}
            blendToSubNav={true}
            selectedIcon={currentPage?.name}
            // onPress={(page: LinkObj) => {
            //   context.setCurrentStack(page.display_path);
            //   navigation.navigate(page.title);
            // }}
          />
        </View>
        <View style={GlobalStyles.flex} />
        <View
          style={{
            position: 'absolute',

            right: 0,
            top: 0,
          }}>
          <Socials />
        </View>
      </View>

      {(!productMenu || navItems[productMenu].pages.length === 0) && (
        <View style={GlobalStyles.layoutSpacerSMHeight} />
      )}

      {productMenu && (
        <View style={[GlobalStyles.row, backgroundColor, subNavStyles]}>
          <Pressable
            onPress={() => {
              setCurrentStack(undefined);
              reset(navigation);
            }}
            style={[
              GlobalStyles.iconWidthMediumSmall,
              GlobalStyles.iconHeightSmall,
              GlobalStyles.justifyCenter,
              GlobalStyles.alignCenter,
              GlobalStyles.marginRightXXS,
              backgroundColor,
            ]}>
            <View
              style={[
                GlobalStyles.iconHeightExtraSmall,
                GlobalStyles.iconWidthExtraSmall,
              ]}>
              <Image
                source={
                  whiteOrBlackText(bgColor || '') === Colours.black
                    ? (Home as any)
                    : (HomeWhite as any)
                }
                style={[GlobalStyles.fullHeight, GlobalStyles.fullWidth]}
              />
            </View>
          </Pressable>
          {navItems[productMenu].pages.map(page => {
            if (page?.title === 'BlogPost') return;
            const pages = navItems.products.pages.filter(a => !!a);
            const prod = pages.find(
              p => p?.path === productMenu.split('secondary/')[1],
            );
            const navText =
              prod?.display_path === page.display_path
                ? prod.title
                : page.title;
            const currentRouteBasedOnDisplayName = pages.find(
              p => p?.display_title === routeName,
            );

            const currentRoute =
              routeName.includes(page.display_title) ||
              routeName === page.title ||
              currentRouteBasedOnDisplayName?.path === page.display_path;

            return (
              <LinearGradient
                key={`desktopCrum${page.path}`}
                webGradientType={currentRoute ? 'webBreadCrumb' : undefined}
                colours={[]}>
                <BreadCrumbItem
                  text={page.display_title}
                  link={page.page_type === 'link' ? page.display_path : ''}
                  navText={navText}
                  navigation={navigation}
                  canNav={!currentRoute}
                  bgColor={background}
                  style={[
                    width < 1270
                      ? GlobalStyles.minWidthIconSizeMediumLarge
                      : GlobalStyles.minWidthIconSizeExtraLarge,
                    GlobalStyles.iconHeightSmall,
                  ]}
                />
              </LinearGradient>
            );
          })}
        </View>
      )}
    </LinearGradient>
  );
};

export default DesktopMainNav;
