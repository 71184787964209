import {StyleSheet, View} from 'react-native';
import React from 'react';
import LottoRow from '../../components/numbers/LottoRow';
import ProductButtons from './ProductButtons';
import {allProducts} from '../../products';
import DateAndTime from 'date-and-time';
import Ordinal from 'date-and-time/plugin/ordinal';
DateAndTime.plugin(Ordinal);
import Text from '../../components/text/FnsText';

type Props = {
  screenType: string;
  item: any;
};

const NumbersCarouselItem = ({screenType, item}: Props) => {
  const product = allProducts.find(p => p?.code === item?.product_code);
  const is49s = product?.code === '49' || product?.code === 'IL';

  const lottoRowWidthMap = {
    desktop: 465,
    tablet: is49s ? 325 : 220,
    phone: is49s ? 245 : 175,
  };

  const ballSizeMap = {
    desktop: 'Medium',
    tablet: 'SmallMedium',
    phone: 'Small',
  };

  const textSizeMap = {
    desktop: 'ExtraLarge',
    tablet: 'Large',
    phone: 'Medium',
  };

  let drawType = 'Draw';
  if (product?.path === 'irish-lotto-bet') {
    drawType = `Main ${drawType}`;
  } else if (
    product?.path === '39s' ||
    product?.path === 'lotto365' ||
    product?.path === '15s'
  ) {
    drawType = `Draw ${item.event.event_number || 1}`;
  } else if (item.event.event_number) {
    drawType = `${
      item.event.event_number === 1 ? 'Lunchtime' : 'Teatime'
    } ${drawType}`;
  } else {
    drawType = `${
      item.event.scheduled_time.includes('12:') ? 'Lunchtime' : 'Teatime'
    } ${drawType}`;
  }

  return (
    <View style={[styles.numberResults, {width: lottoRowWidthMap[screenType]}]}>
      <Text
        decoration={'Bold'}
        size={textSizeMap[screenType]}
        style={styles.text}>
        {drawType}{' '}
        {DateAndTime.format(new Date(item.event.scheduled_time), 'HH:mm')}
      </Text>
      <Text size={textSizeMap[screenType]} style={styles.text}>
        {DateAndTime.format(
          new Date(item.event.scheduled_time),
          'ddd DDD MMMM',
        )}
      </Text>
      <LottoRow
        numbers={item?.event?.drawns}
        product={product?.path}
        size={ballSizeMap[screenType]}
        style={{paddingVertical: 10, alignSelf: is49s ? undefined : 'center'}}
      />
      <ProductButtons onCarousel product={product} />
    </View>
  );
};

export default NumbersCarouselItem;

const styles = StyleSheet.create({
  numberResults: {
    position: 'absolute',
    height: '50%',
    bottom: '15%',
    right: 0,
    paddingRight: 10,
  },
  text: {
    color: 'white',
    alignSelf: 'flex-end',
  },
});
