import React, {useContext, useEffect, useState} from 'react';
import {
  Linking,
  Pressable,
  StyleSheet,
  View,
  Image,
  SafeAreaView,
} from 'react-native';
import {
  DrawerContentScrollView,
  DrawerContentComponentProps, // eslint-disable-line @typescript-eslint/no-unused-vars
} from '@react-navigation/drawer'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {CommonActions, DrawerActions} from '@react-navigation/native';

import LinearGradient from '../../components/global/LinearGradient';
import Text from '../../components/text/FnsText';

import AppContext from '../../context/AppContext';
import {reset} from '../../utils/navigation';
import Layout from '../../assets/Layout';
import Colours from '../../assets/Colours';
import Fonts from '../../assets/Fonts';
import GlobalStyles from '../../assets/styles/';

import {DrawContentProps} from '../../@types/navigation'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {LinkObj} from '../../@types/global'; // eslint-disable-line @typescript-eslint/no-unused-vars

import Hamburger from '../../Images/Hamburger.png';
import Version from './Version';

///////////// Helper Functions
const drawerNavigate = (
  navigation: any,
  currentPage: string,
  page: LinkObj,
  key: string,
) => {
  if (page.page_type === 'link') {
    return Linking.openURL(page.display_path);
  }

  navigation.dispatch({
    ...(currentPage === page.title
      ? DrawerActions.closeDrawer()
      : CommonActions.navigate(page.title)),
    target: key,
  });
};

///////////// Local Components
const LabelText: React.FC<DrawContentProps> = ({text}) => (
  <Text style={styles.text}>{text}</Text>
);

///////////// Functional Component
const DrawerContent: React.FC<DrawerContentComponentProps> = ({
  navigation,
  state,
}) => {
  ///////////// Variables
  const currentPage = state.routes[state.index].name;

  ///////////// Functions
  const clearState = () => {
    setActiveMenu('');
    setActivePage('');
  };

  ///////////// Hooks
  const [activeMenu, setActiveMenu] = useState('');
  const [activePage, setActivePage] = useState('');
  const context = useContext(AppContext);
  const {navItems, setCurrentStack} = context;

  useEffect(() => {
    if (!state.index) {
      setActiveMenu('');
    }
    setActivePage(currentPage || '');
  }, [state.index, currentPage]);

  return (
    <LinearGradient
      colours={[Colours.deepBlue, Colours.oceanBlue]}
      style={styles.container}
      webGradientType={'offCanvasMenu'}>
      <DrawerContentScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.drawerContentScrollViewOverrides}>
        <SafeAreaView>
          <Pressable
            onPress={() => navigation.closeDrawer()}
            style={[
              {paddingHorizontal: 10, paddingVertical: 10},
              styles.marginRight,
              styles.selfEnd,
            ]}>
            <View
              style={[
                GlobalStyles.iconWidthExtraSmall,
                GlobalStyles.iconHeightExtraSmall,
              ]}>
              <Image
                source={Hamburger as any}
                style={[GlobalStyles.fullHeight, GlobalStyles.fullWidth]}
              />
            </View>
          </Pressable>

          <View style={styles.body}>
            <Pressable
              style={styles.underlinedLinkObj}
              onPress={() => {
                clearState();
                if (currentPage === 'Home') {
                  return navigation.closeDrawer();
                }
                setCurrentStack(undefined);
                reset(navigation);
              }}>
              <LabelText text={'Home'} />
            </Pressable>

            {navItems.products.pages &&
              navItems.products.pages.map(page => {
                if (!navItems[`secondary/${page.path}`]?.pages) {
                  return null;
                }

                const activeMenuStyle =
                  activeMenu === page.path
                    ? {
                        backgroundColor: Colours.activeMenuItemBlue,
                        paddingLeft: bodyMargin + containerPadding,
                        marginLeft: -(bodyMargin + containerPadding),
                      }
                    : {};
                const onPress =
                  navItems[`secondary/${page.path}`].pages &&
                  navItems[`secondary/${page.path}`].pages.length === 0
                    ? () => {
                        setCurrentStack(page.display_title);
                        drawerNavigate(
                          navigation,
                          currentPage,
                          page,
                          state.key,
                        );
                      }
                    : () =>
                        setActiveMenu(
                          activeMenu === page.path ? '' : page.path,
                        );

                return (
                  <React.Fragment key={`products${page.path}`}>
                    <View style={activeMenuStyle}>
                      <Pressable
                        style={styles.underlinedLinkObj}
                        onPress={() => onPress()}>
                        <LabelText text={page.display_title} />
                      </Pressable>
                    </View>

                    {activeMenu === page.path &&
                      navItems[`secondary/${activeMenu}`]?.pages &&
                      navItems[`secondary/${activeMenu}`].pages.map(
                        (p: LinkObj) => {
                          if (p?.title === 'BlogPost') {
                            return null;
                          }
                          const currentRouteBasedOnDisplayName =
                            navItems.products.pages.find(
                              pp => pp?.display_title === activePage,
                            );

                          const activePageStyle =
                            activePage === p.title ||
                            currentRouteBasedOnDisplayName?.path ===
                              p.display_path
                              ? styles.selectedBG
                              : {};

                          return (
                            <View
                              key={`secondary${p.path}`}
                              style={activePageStyle}>
                              <Pressable
                                style={[
                                  styles.underlinedLinkObj,
                                  styles.paddingLeft,
                                ]}
                                onPress={() => {
                                  const navPage =
                                    p.display_path === page.display_path
                                      ? page
                                      : p;
                                  setCurrentStack(page.display_path);
                                  setActivePage(p.title);
                                  drawerNavigate(
                                    navigation,
                                    currentPage,
                                    navPage,
                                    state.key,
                                  );
                                }}>
                                <LabelText text={p.display_title} />
                              </Pressable>
                            </View>
                          );
                        },
                      )}
                  </React.Fragment>
                );
              })}

            <View style={styles.marginTop}>
              {navItems?.footer?.pages &&
                navItems?.footer?.pages.map(page => (
                  <Pressable
                    key={`footer${page.path}`}
                    style={styles.paddingVertical}
                    onPress={() => {
                      setCurrentStack(undefined);
                      clearState();
                      drawerNavigate(navigation, currentPage, page, state.key);
                    }}>
                    <LabelText text={page.display_title} />
                  </Pressable>
                ))}
              <Version />
            </View>
          </View>
        </SafeAreaView>
      </DrawerContentScrollView>
    </LinearGradient>
  );
};

const containerPadding = Layout.spacer;
const bodyMargin = Layout.spacerXL;

const styles = StyleSheet.create({
  drawerContentScrollViewOverrides: {
    // paddingTop: 0,
    flexGrow: 1,
  },
  container: {
    flex: 1,
    paddingVertical: Layout.spacerSM,
    paddingHorizontal: containerPadding,
  },
  body: {
    marginTop: Layout.spacer,
    marginLeft: 0,
  },

  underlinedLinkObj: {
    borderBottomWidth: Layout.borderWidthNarrow,
    borderColor: Colours.white,
    paddingVertical: Layout.spacerSM,
  },
  text: {
    fontSize: Fonts.sizeSubhead,
    color: Colours.white,
  },
  selectedBG: {
    backgroundColor: Colours.activeMenuItemBlue,
  },

  // Generic Styles
  marginRight: {
    marginRight: Layout.spacerSM,
  },
  marginTop: {
    marginTop: Layout.spacerL,
  },
  paddingVertical: {
    paddingVertical: Layout.spacerXS,
  },
  paddingLeft: {
    paddingLeft: Layout.spacerL,
  },
  selfEnd: {
    alignSelf: 'flex-end',
  },
});

export default DrawerContent;
