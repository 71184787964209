import React, {useMemo, useState} from 'react';
import {Platform, StyleSheet, View} from 'react-native';
import {ChildrenProps} from '../../@types/global'; // eslint-disable-line @typescript-eslint/no-unused-vars
import BannerAd from '../../components/global/BannerAd/BannerAd';

type Props = {};

const ContentBlockContainer = ({children}: ChildrenProps) => {
  const [containerWidth, setContainerWidth] = useState(0);

  const screenType = useMemo(() => {
    if (containerWidth > 900) return 'desktop';
    if (containerWidth > 600) return 'tablet';
    return 'phone';
  }, [containerWidth]);

  const itemSizeMap = {
    desktop: containerWidth / 4.2,
    tablet: containerWidth / 4.3,
    phone: containerWidth / 2.1,
  };

  const itemWidth = itemSizeMap[screenType] - (screenType !== 'phone' ? 10 : 0);

  return (
    <View
      onLayout={e => setContainerWidth(e.nativeEvent.layout.width)}
      style={[styles.container, styles[screenType]]}>
      {React.Children.map(children, (child, index) => {
        return (
          <>
            <View
              key={index}
              style={[
                styles.childContainer,
                {
                  minWidth: itemWidth,
                  maxWidth: itemWidth,
                  width: itemWidth,
                  height: itemWidth,
                  maxHeight: itemWidth,
                  minHeight: itemWidth,
                },
                {
                  marginHorizontal: screenType !== 'phone' ? 10 : 0,
                  maxHeight: screenType === 'phone' ? 180 : 200,
                },
              ]}>
              {React.cloneElement(child, {
                maxWidth: 250,
                parent: 'content-block',
              })}
            </View>
            {index === 1 && screenType === 'phone' && Platform.OS === 'web' ? (
              <View
                style={{
                  minHeight: 250,
                  width: containerWidth,
                  marginBottom: 10,
                }}>
                <BannerAd
                  richMedia={false}
                  placementId={'6565ee0809ba2171d1441121'}
                />
              </View>
            ) : null}
          </>
        );
      })}
    </View>
  );
};

export default ContentBlockContainer;

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    flexWrap: 'wrap',
    // height: '100%',
    width: '100%',
    flexDirection: 'row',
  },
  childContainer: {
    flex: 1,
    height: 200,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
  },
  phone: {justifyContent: 'space-between'},
  tablet: {
    paddingLeft: '1%',
  },
  desktop: {
    paddingLeft: '0%',
  },
});
