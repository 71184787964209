import React from 'react'
import {View} from 'react-native'

import Text from '../text/FnsText'
import Row from './Row'

import GlobalStyles from '../../assets/styles'

const AgeWarning: React.FC = () => {
  return (
    <Row>
      <View style={[
        GlobalStyles.borderWidthSeparator,
        GlobalStyles.row,
        GlobalStyles.borderWhite,
        GlobalStyles.borderRadiusSM,
        ]}>
        <View style={[GlobalStyles.row, GlobalStyles.alignCenter]}>
          <View style={[
            GlobalStyles.borderWidthSeparator,
            GlobalStyles.borderWhite,
            GlobalStyles.borderRadiusCircle,
            GlobalStyles.paddingVerticalXXS,
            GlobalStyles.marginRightSM,
            GlobalStyles.marginLeftXS,
            GlobalStyles.marginVerticalXS,
            ]}>
            <Text size={'ExtraSmall'} style={GlobalStyles.whiteText}>
              {' 18+ '}
            </Text>
          </View>
          <Text size={'ExtraSmall'} style={[GlobalStyles.whiteText, GlobalStyles.marginRightXS]}>
            {'Players must be\naged 18 or over'}
          </Text>
        </View>
      </View>
    </Row>
  )
}

export default AgeWarning
