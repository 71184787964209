import React, {useContext, useState} from 'react';
import {
  Pressable,
  Linking,
  View,
  useWindowDimensions,
  Image,
} from 'react-native';
import HTML from 'react-native-render-html';

import APIImage from '../global/FnsImage/';
import Text from '../text/FnsText';

import {imageFromAPI} from '../../utils';
import AppContext from '../../context/AppContext';

import GlobalStyles from '../../assets/styles';
import Fonts from '../../assets/Fonts';

import {BookmakerObj, StringIndex} from '../../@types/global'; // eslint-disable-line @typescript-eslint/no-unused-vars
import Button from '../global/Button';

const ChevronRightBlue = '/ChevronRightBlue.svg';
const ChevronDownBlue = '/ChevronDownBlue.svg';

const fontMapping = {
  Small: Fonts.sizeFootnote,
  SmallMedium: Fonts.sizeSubhead,
  MediumLarge: Fonts.sizeTitle5,
} as StringIndex;

const Bookmaker: React.FC<{bookmaker: BookmakerObj}> = ({bookmaker}) => {
  const [expanded, setExpanded] = useState(false);
  const context = useContext(AppContext);
  const {width} = useWindowDimensions();
  const textSize =
    width < 1450
      ? 'Small'
      : width < 1600
      ? 'SmallMedium'
      : context.platform === 'desktop'
      ? 'MediumLarge'
      : 'SmallMedium';

  return (
    <>
      <Pressable
        style={[
          GlobalStyles.iconHeightSmallMedium,
          GlobalStyles.lightGrayBG,
          GlobalStyles.marginTopSM,
          GlobalStyles.row,
          GlobalStyles.alignCenter,
          expanded
            ? GlobalStyles.borderRadiusTopSM
            : GlobalStyles.borderRadiusSM,
          bookmaker.wrapperStyle,
        ]}
        onPress={() => setExpanded(!expanded)}>
        <View
          style={[
            GlobalStyles.advertisingBannerWidthM,
            GlobalStyles.iconHeightSmallMedium,
            GlobalStyles.borderRadiusLeftSM,
            GlobalStyles.hiddenOverflow,
            bookmaker.imageWrapperStyle,
          ]}>
          <APIImage
            loading="lazy"
            resizeMode={'cover'}
            style={{height: '100%', width: '100%'}}
            imageURL={imageFromAPI(bookmaker.logo_image_url)}
          />
        </View>

        <Text
          size={textSize}
          style={[
            GlobalStyles.paddingLeftSM,
            GlobalStyles.flexGrow,
            GlobalStyles.flex,
          ]}>
          {bookmaker.content}
        </Text>

        <View
          style={[
            GlobalStyles.iconHeightExtraSmall,
            GlobalStyles.iconWidthExtraSmall,
            expanded ? GlobalStyles.marginRightXS : undefined,
          ]}>
          <Image
            source={{
              uri: expanded
                ? (ChevronDownBlue as any)
                : (ChevronRightBlue as any),
            }}
            style={[GlobalStyles.fullWidth, GlobalStyles.fullHeight]}
          />
        </View>
      </Pressable>

      {expanded && (
        <View
          style={[
            GlobalStyles.paddingLeftAdvertisingBannerM,
            GlobalStyles.lightGrayBG,
            GlobalStyles.flex,
            GlobalStyles.paddingBottomSM,
            GlobalStyles.borderRadiusBottomSM,
          ]}>
          <View style={[GlobalStyles.paddingHorizontalSM]}>
            <HTML
              baseFontStyle={{fontSize: fontMapping[textSize]}}
              source={{html: bookmaker.terms_and_conditions}}
            />
          </View>
          <View
            style={[
              GlobalStyles.alignEnd,
              GlobalStyles.paddingRightSM,
              GlobalStyles.paddingTopSM,
            ]}>
            <Button
              text={bookmaker.button_text || 'Go to Site'}
              onpress={() => Linking.openURL(bookmaker.website)}
            />
          </View>
        </View>
      )}
    </>
  );
};

export default Bookmaker;
