import React, {useContext} from 'react'
import {Linking, Pressable, useWindowDimensions} from 'react-native'

import Text from '../text/FnsText'

import AppContext from '../../context/AppContext'
import GlobalStyles from '../../assets/styles/'
import {whiteOrBlackText} from '../../utils'

import {BreadCrumbItemProps} from '../../@types/navigation' // eslint-disable-line @typescript-eslint/no-unused-vars

const BreadCrumbItem: React.FC<BreadCrumbItemProps> = ({
  text,
  navigation,
  canNav,
  navText,
  style,
  bgColor,
  link,
}) => {
  const {width} = useWindowDimensions()
  const context = useContext(AppContext)
  const desktop = context.platform === 'desktop'
  const textColor = {color: whiteOrBlackText(bgColor)}

  return (
    <Pressable
      onPress={() => link ? Linking.openURL(link) : canNav ? navigation.navigate((navText || text), {presenter: undefined}) : undefined}
      style={[
        GlobalStyles.marginRightXXS,
        desktop ? undefined : GlobalStyles.opaqueBGOneTenth,
        GlobalStyles.justifyCenter,
        GlobalStyles.alignCenter,
        GlobalStyles.paddingHorizontalXS,
        style,
        ]}>

      <Text
        size={desktop ? 'Medium' : 'SmallMedium'}
        numberOfLines={1}
        style={[
          textColor,
          (desktop && width > 1270) ? GlobalStyles.paddingHorizontalXS : undefined,
        ]}>
        {text}
      </Text>
    </Pressable>
  )
}

export default BreadCrumbItem
