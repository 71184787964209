import React from 'react'
import {Pressable} from 'react-native'

import Text from '../text/FnsText'

import {ButtonProps} from '../../@types/components' // eslint-disable-line @typescript-eslint/no-unused-vars

import Colours from '../../assets/Colours'
import GlobalStyles from '../../assets/styles'

const Button: React.FC<ButtonProps> = ({
  onpress,
  text,
  textSize,
  textStyle,
  textDecoration,
  containerStyles,
  inverse,
  disabled,
  uppercase = true,
}) => {
  const inverseBG = inverse ? ({
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  }) : ({
    backgroundColor: Colours.black,
    borderColor: Colours.black,
  })
  const inverseText = inverse ? GlobalStyles.blackText : GlobalStyles.whiteText

  return (
    <Pressable
      disabled={disabled}
      onPress={onpress}
      style={[
        GlobalStyles.borderRadiusSM,
        GlobalStyles.borderWidthSeparator,
        inverseBG,
        containerStyles,
      ]}>
      <Text
        size={textSize || 'ExtraSmall'}
        decoration={textDecoration}
        style={[
          uppercase && GlobalStyles.uppercase,
          GlobalStyles.textCenter,
          GlobalStyles.paddingHorizontalSM,
          GlobalStyles.paddingVerticalXXS,
          inverseText,
          textStyle,
        ]}>
        {text}
      </Text>
    </Pressable>
  )
}

export default Button
