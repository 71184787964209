import {Linking, Pressable} from 'react-native';
import React, {useState} from 'react';
import {imageFromAPI} from '../../utils';
import Image from '../../components/global/Image';

type Props = {
  website: string;
  imageURL: string;
};

const PopupTakeoverItem = ({website, imageURL}: Props) => {
  const [containerHeight, setContainerHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  return (
    <Pressable
      onPress={() => website && Linking.openURL(website)}
      onLayout={e => {
        setContainerHeight(e.nativeEvent.layout.height);
        setContainerWidth(e.nativeEvent.layout.width);
      }}
      style={{flex: 1}}>
      <Image
        style={{
          height: containerHeight,
          width: containerWidth,
          // maxWidth: 1000,
        }}
        resizeMode="stretch"
        imageURL={imageFromAPI(imageURL)}
      />
    </Pressable>
  );
};

export default PopupTakeoverItem;
