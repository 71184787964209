import React, {createRef, useEffect, useState} from 'react';
import {
  View,
  StatusBar,
  useWindowDimensions,
  Platform,
  SafeAreaView,
  ScrollView,
} from 'react-native';

import AppNavigation from './navigation/AppNavigation';
import ActivityIndicator from './components/global/ActivityIndicator';

import Client from './client/SisClient';
import Alerts from './utils/Alerts';
import {AppProvider} from './context/AppContext';
import Splashscreen from './pages/SplashScreen';

import GlobalStyles from './assets/styles';

import {AppContextInterface} from './@types/context'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {SampleLinking, dynamicLinking} from './@types/'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {LinkObj, BookmakerObj} from './@types/global'; // eslint-disable-line @typescript-eslint/no-unused-vars

import {logSiteVisit, requestUserPermission} from './utils/Firebase/';
import {HelmetProvider} from 'react-helmet-async';

const productOrdering = {
  '49s': 0,
  'irish-lotto-bet': 2,
  'virtual-horse-racing': 5,
  'virtual-greyhound-racing': 6,
  '39s': 3,
  lotto365: 1,
  'the-fount': 7,
  '15s': 4,
} as any;

const SPLASH_SCREEN_DURATION = 2000;

const App: React.FC = () => {
  const scrollRef = createRef<ScrollView>();

  const [showSplashScreen, setShowSplashScreen] = useState<boolean>(
    Platform.select({native: true, default: false}),
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [navItems, setNavItems] = useState({} as AppContextInterface);
  const [bookmakers, setBookmakers] = useState([] as Array<BookmakerObj>);
  const [linking, setLinking] = useState<SampleLinking>();
  const [platform, setPlatform] = useState<string>();
  const [currentStack, setCurrentStack] = useState<any>(undefined);
  const [enableScrolling, setEnableScrolling] = useState<boolean>(true);
  const [initialLoadComplete, setInitialLoadComplete] =
    useState<boolean>(false);
  const [wrapperBackButtonFunction, setWrapperBackButtonFunction] = useState<
    (() => void) | undefined
  >(undefined);
  const [pages, setPages] = useState([] as Array<LinkObj>);
  const [showPartnerPopup, setShowPartnerPopup] = useState<boolean>(false);

  useEffect(() => {
    const loadContent = async () => {
      const resp = await Client.loadAppData();
      if (!resp.success) {
        return Alerts.unexpectedError();
      }

      ///////////// Map over navItems to get in the correct format for the linking prop

      const paths = [] as Array<string>;

      // order the products by the productOrdering object
      const orderedProducts = [];
      for (let product of resp.content.menu.products.pages) {
        orderedProducts[productOrdering[product.display_path]] = product;
      }

      resp.content.menu.products.pages = orderedProducts;

      // Ensure we map over products first
      resp.content.menu.products.pages.map((link: LinkObj) => {
        dynamicLinking.config.screens[link.title] = link.display_path;
        paths.push(link.display_path);
      });

      Object.keys(resp.content.menu).map(key => {
        if (key === 'products' || key === 'footer') {
          return;
        }

        resp.content.menu[key].pages.map((link: LinkObj, index: number) => {
          if (link.page_type === 'link') {
            return;
          }

          if (dynamicLinking.config.screens[link.title]) {
            const product = key.split('/')[1];
            link.title = `${product}${index}?${link.display_path}`;
            if (!link.display_path.includes(product)) {
              link.display_path = `${product}/${link.display_path}`;
            }
          }
          if (paths.includes(link.display_path)) {
            return;
          }

          dynamicLinking.config.screens[link.title] = link.display_path;
          paths.push(link.display_path);
        });
      });

      resp.content.pages.map((link: LinkObj) => {
        if (link.page_type === 'link') {
          return;
        }
        if (paths.includes(link.display_path)) {
          return;
        }

        if (dynamicLinking.config.screens[link.title]) {
          link.title = `${link.title}${link.display_path}`;
        }

        dynamicLinking.config.screens[link.title] = link.display_path;
        paths.push(link.display_path);
      });

      if (Platform.OS !== 'web') {
        logSiteVisit();
        if (Platform.OS === 'ios') {
          requestUserPermission();
        }
      }

      // // inject blog Post into navigation
      resp.content.menu['secondary/49s'].pages.push({
        title: 'BlogPost',
        display_path: '49s/offair/:title/:id',
        display_title: 'BlogPost',
        page_type: 'page',
        path: '49s/offair/:title/:id',
        hide_from_main_nav: true,
      });

      ///////////// Set state
      setNavItems(resp.content.menu);
      setPages(resp.content.pages);
      setBookmakers(resp.content.bookmakers);
      setLinking(dynamicLinking);
      setLoading(false);
      if (showSplashScreen) {
        setTimeout(() => {
          setShowSplashScreen(false);
        }, SPLASH_SCREEN_DURATION);
      }
    };

    loadContent();
  }, [showSplashScreen]);

  const {width} = useWindowDimensions();
  if (width > 1030 && platform !== 'desktop') {
    setPlatform('desktop');
  }
  // else if (width <= 1030 && width >= 750 && platform !== 'tablet') {
  //   setPlatform('tablet')
  // }
  else if (width < 750 && platform !== undefined) {
    setPlatform(undefined);
  }

  if (loading) {
    return <ActivityIndicator />;
  }
  if (showSplashScreen) {
    return <Splashscreen />;
  }

  const statusBarColor =
    navItems[`secondary/${currentStack}`]?.colors?.background_color;

  return (
    <HelmetProvider>
      <AppProvider
        value={{
          dynamicLinking,
          navItems,
          pages,
          bookmakers,
          scrollRef,
          platform,
          setCurrentStack,
          currentStack,
          enableScrolling,
          setEnableScrolling,
          initialLoadComplete,
          setInitialLoadComplete,
          wrapperBackButtonFunction,
          setWrapperBackButtonFunction,
          setShowPartnerPopup,
          showPartnerPopup,
        }}>
        <ScrollView
          nativeID="site-content"
          ref={scrollRef}
          scrollEnabled={Platform.select({
            web: enableScrolling,
            default: false,
          })}
          contentContainerStyle={GlobalStyles.flexGrow}>
          <View style={GlobalStyles.flex}>
            <StatusBar barStyle={'light-content'} />
            <SafeAreaView
              style={{backgroundColor: statusBarColor || '#1F00D1'}}
            />
            <AppNavigation linking={linking} />
          </View>
        </ScrollView>
      </AppProvider>
    </HelmetProvider>
  );
};

export default App;
