import {CommonActions} from '@react-navigation/native';

export const reset = (navigation: any) => {
  navigation.dispatch(
    CommonActions.reset({
      index: 0,
      routes: [
        { name: 'Home' },
      ],
    }),
  );
}
