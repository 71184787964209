/**
 * Component to display center leaderboard advertisement
 */

import React, {useContext} from 'react';
import {Pressable, Linking, Platform} from 'react-native';

import Image from '../global/FnsImage/';

import Client, {endpoint} from '../../client/SisClient';

import GlobalStyles from '../../assets/styles';

import {AdvertisingProps} from '../../@types/components'; // eslint-disable-line @typescript-eslint/no-unused-vars

import {useNavigation} from '@react-navigation/native';
import AppContext from '../../context/AppContext';

const AdvertisingBanner: React.FC<AdvertisingProps> = ({
  advertisement,
  placementType,
  style,
}) => {
  const {small_image_url, link, color, campaign_id} = advertisement;
  const path = `${endpoint}/${small_image_url}`;
  const bgColour = {backgroundColor: color};
  const navigation = useNavigation();
  const {dynamicLinking, setCurrentStack} = useContext(AppContext);

  return (
    <Pressable
      onPress={() => {
        Client.sendAdvertisingClickInformation(campaign_id, placementType);
        if (Platform.OS === 'web' || !link.includes('https://49s.co.uk')) {
          Linking.openURL(link);
        } else {
          const screen = link.replace('https://49s.co.uk/', '');
          const t: any =
            dynamicLinking.config.screens &&
            Object.entries(dynamicLinking.config.screens).find(
              (screenObj: any) => screenObj[1] === screen,
            );
          if (t) {
            setCurrentStack(t[1].split('/')[0]);
            navigation.navigate(t[0]);
          }
        }
      }}
      style={[
        GlobalStyles.justifyCenter,
        GlobalStyles.alignCenter,
        GlobalStyles.fullHeight,
        GlobalStyles.width90pct,
        GlobalStyles.borderRadiusSM,
        bgColour,
        style,
      ]}>
      <Image
        style={{width: '100%'}}
        loading="eager"
        resizeMode="contain"
        imageURL={path}
        fetchpriority="high"
      />
    </Pressable>
  );
};

export default AdvertisingBanner;
