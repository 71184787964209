import {View, StyleSheet} from 'react-native';
import React, {useMemo, useState} from 'react';

const PartnerOfferContainer = ({children}: Props) => {
  const [containerWidth, setContainerWidth] = useState(0);

  const screenType = useMemo(() => {
    if (containerWidth > 900) return 'desktop';
    if (containerWidth > 600) return 'tablet';
    return 'phone';
  }, [containerWidth]);

  const itemSizeMap = {
    desktop: containerWidth / 3.1,
    tablet: containerWidth / 3.2,
    phone: containerWidth,
  };

  const itemWidth = itemSizeMap[screenType];

  return (
    <View
      style={[styles.container]}
      onLayout={e => setContainerWidth(e.nativeEvent.layout.width)}>
      {React.Children.map(children, child => {
        return (
          <View
            style={[
              {minWidth: itemWidth - 1, height: itemWidth / 2},
              styles[screenType],
            ]}>
            {child}
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: 10,
  },
  phone: {
    marginBottom: 10,
  },
  tablet: {
    marginBottom: 10,
    // paddingLeft: '4%',
    marginHorizontal: '1%',
  },
  desktop: {
    margin: '0.5%',
  },
});

export default PartnerOfferContainer;
