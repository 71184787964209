/**
 * File to hold all layout sizes uses throughout app
 */

import { Dimensions } from 'react-native'
import {StringIndex} from '../@types/global' // eslint-disable-line @typescript-eslint/no-unused-vars

export default {
  horizontalPaddingPercent: '5%',

  // Lozenge
  lozengeIconHeightL: 160,
  lozengeIconHeightM: 110,

  // Advertising
  advertisingBannerXXL: 300,
  advertisingBannerXL: 220,
  advertisingBannerL: 180,
  advertisingBannerM: 150,
  advertisingBannerMS: 140,
  advertisingBannerSM: 135,
  advertisingBannerS: 120,

  // Icon Size
  iconSizeExtraExtraLarge: Dimensions.get('window').width > 700 ? 600 :  120,
  iconSizeExtraLarge: 100,
  iconSizeLarge: 90,
  iconSizeMediumLarge: 80,
  iconSizeMedium: 60,
  iconSizeMediumSmall: 50,
  iconSizeSmallMedium: 40,
  iconSizeSmall: 30,
  iconSizeExtraSmall: 20,
  iconSizeExtraExtraSmall: 15,
  iconSizeXXXSmall: 10,

  // Spacer
  spacerXXXL: 75,
  spacerXXL: 60,
  spacerXL: 50,
  spacerL: 40,
  spacerML: 30,
  spacer: 20,
  spacerMS: 15,
  spacerSM: 10,
  spacerS: 8,
  spacerXS: 5,
  spacerXXS: 3,

  // REM
  rem03: 4.8,
  rem05: 8,
  rem1: 16,
  rem2: 32,
  rem3: 48,
  rem10: 160,
  rem16: 256,
  rem20: 320,
  rem30: 480,
  rem60: 960,

  // Border Radius
  borderRadiusLG: 20,
  borderRadius: 10,
  borderRadiusSM: 5,
  borderRadiusXS: 3,

  // Border Width
  borderWidthNarrow: 1,
  borderWidthSeparator: 2,
  borderWidthOutline: 3,
} as StringIndex
