/**
 * File to hold all colours used in the app. Currently, pretty bare until we are given more designs.
 * We have access to colourVaraint and lightenDarkenColor functions through:
 * https://github.com/onpace/myworkapp-mobile/blob/master/assets/Colours.js#L45
 */

import {StringIndex} from '../@types/global'; // eslint-disable-line @typescript-eslint/no-unused-vars

const Colours = {
  brandYellow: '#ccf41b',
  brandBlue: '#1c09da',
  brandDarkBlue: '#1b025b',

  background: '#f2f2f2',

  gray100: '#f8f9fa',
  gray200: '#e9ecef',
  gray300: '#dee2e6',
  gray400: '#ced4da',
  gray500: '#adb5bd',
  gray600: '#6c757d',
  gray700: '#495057',
  gray800: '#343a40',
  gray900: '#212529',

  white: '#ffffff',
  black: '#000000',

  // Hot n Cold
  hot: '#e83b3b',
  cold: '#0961da',
  allNumbersOrange: '#ff8400',

  // Menu
  activeMenuItemBlue: '#213e94',

  // Nav
  royalBlue: '#1f17da',
  deepBlue: '#16184D',
  oceanBlue: '#2f62d2',

  // Lotto Ball Colours
  irishLottoBall: '#57D846',
  limeGreenBall: '#78d62d',
  redBall: '#f33a32',
  orangeBall: '#fe690c',
  yellowBall: '#ede030',
  brownBall: '#814c1d',
  purpleBall: '#a92fc6',
  royalBlueBall: '#196bb2',

  // Product Colours
  '49s': '#2f62d2',
  'irish-lotto-bet': '#509b33',
  'virtual-horse-racing': '#cd374b',
  'virtual-greyhound-racing': '#FEC900',
  '39s': '#a92fc6',
  lotto365: '#1F1F1F',
  '15s': '#eb5a04',

  // colors by game code
  '49': '#2f62d2',
  IL: '#509b33',
  HR: '#cd374b',
  DG: '#FEC900',
  '39': '#a92fc6',
  '36': '#1F1F1F',
  '15': '#eb5a04',

  // pantone
  pantone: {
    yellow: '#fedf00',
    lightBlue: '#8abad3',
    orange: '#FE5000',
    purple: '#bb29bb',
  },

  // Extras
  twitterBG: '#009afc',
  footerPurple: '#14032E',
} as StringIndex;

export default Colours;
