/**
 * Using the `navItems` from our Context, we map over it's keys and we are able to generate all our Drawer.Navigator's Screens.
 * We listen for duplicates, using the variable pagePaths.
 */

import React, {Suspense, useContext, useState} from 'react';
import {StyleSheet, Platform, SafeAreaView} from 'react-native';
import {createDrawerNavigator} from '@react-navigation/drawer';

import DrawContent from './DrawContent';
import MainNav from '../../components/navigation/MainNav';
import DesktopMainNav from '../../components/navigation/DesktopMainNav';

import HomePage from '../../pages/home/index';

const GenericPage = React.lazy(() => import('../../pages/GenericPage'));

// const HomePage = React.lazy(() => import('../../pages/home/index'));

import AppContext from '../../context/AppContext';

import staticPageMapping from '../utils/staticPageMapping';

import Colours from '../../assets/Colours';

import {LinkObj, StringIndex} from '../../@types/global'; // eslint-disable-line @typescript-eslint/no-unused-vars
import Partners from '../../components/partners/Partners';

const Drawer = createDrawerNavigator();

const DrawerNavigator: React.FC = () => {
  const context = useContext(AppContext);
  const {
    navItems,
    pages,
    platform,
    setEnableScrolling,
    enableScrolling,
    scrollRef,
  } = context;
  const desktop = platform === 'desktop';
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const Component = desktop ? DesktopMainNav : MainNav;
  const container = {width: desktop ? '25%' : '85%'};
  const homeName = 'Home';
  const pagePaths = [homeName] as Array<string>;

  return (
    <Drawer.Navigator
      initialRouteName={homeName}
      drawerContent={props => {
        const isDrawerOpen = props.state.history.some(
          it => it.type === 'drawer',
        );
        if (isDrawerOpen) {
          if (!drawerOpen) {
            (global as any).isDrawerOpen = true;
            setDrawerOpen(true);
          }
          scrollRef?.current?.scrollTo({y: 0, animated: false});
          if (enableScrolling) {
            setEnableScrolling(false);
          }
        }
        if (!isDrawerOpen) {
          if (drawerOpen) {
            (global as any).isDrawerOpen = false;
            setDrawerOpen(false);
          }
          if (!enableScrolling) {
            setEnableScrolling(true);
          }
        }

        return <DrawContent {...props} />;
      }}
      screenOptions={{
        headerShown: true,
        header: props => (
          <SafeAreaView style={{backgroundColor: Colours.brandBlue}}>
            <Component
              navigation={props.scene.descriptor.navigation}
              currentPage={props.scene.route}
            />
          </SafeAreaView>
        ),
      }}
      overlayColor={'transparent'}
      drawerStyle={
        drawerOpen
          ? [
              container,
              styles.drawerNavigator,
              {backgroundColor: Colours.brandBlue},
            ]
          : container
      }>
      <Drawer.Screen name={homeName}>
        {() => <HomePage path={'home'} colors={navItems.products.colors} />}
      </Drawer.Screen>

      <Drawer.Screen name={'Partners'}>{() => <Partners />}</Drawer.Screen>

      {navItems &&
        Object.keys(navItems).map(
          key =>
            navItems[key].pages &&
            navItems[key].pages.map((page: LinkObj) => {
              if (pagePaths.includes(page.title)) {
                return;
              }
              pagePaths.push(page.title);

              const Page = staticPageMapping[page.path];
              const product = key === 'products';
              const footer = key === 'footer';
              const logoURL =
                product && navItems[`secondary/${page.path}`]
                  ? navItems[`secondary/${page.path}`].logo_image_url
                  : '';
              const colors =
                product || footer
                  ? navItems[`secondary/${page.path}`]
                    ? navItems[`secondary/${page.path}`].colors
                    : navItems[key].colors
                  : navItems[key].colors;

              return (
                <Drawer.Screen
                  key={page.path}
                  name={page.title}
                  options={{
                    header: props => (
                      <SafeAreaView
                        style={{backgroundColor: colors.background_color}}>
                        <Component
                          navigation={props.scene.descriptor.navigation}
                          currentPage={props.scene.route}
                          logoURL={logoURL || navItems[key].logo_image_url}
                          bgColor={colors.background_color}
                        />
                      </SafeAreaView>
                    ),
                    title: page.display_title,
                  }}>
                  {() => {
                    const productMenu = product
                      ? `secondary/${page.path}`
                      : footer
                      ? undefined
                      : key;

                    return Page ? (
                      <Suspense fallback={<></>}>
                        <Page
                          path={page.display_path}
                          colors={colors}
                          productMenu={productMenu}
                        />
                      </Suspense>
                    ) : (
                      <Suspense fallback={<></>}>
                        <GenericPage
                          path={page.display_path}
                          title={page.display_title}
                          colors={colors}
                          productMenu={productMenu}
                        />
                      </Suspense>
                    );
                  }}
                </Drawer.Screen>
              );
            }),
        )}

      {pages &&
        pages.map((page: LinkObj) => {
          if (pagePaths.includes(page.title)) {
            return;
          }
          pagePaths.push(page.title);

          const productMenu = `secondary/${page.display_path.split('/')[0]}`;

          return (
            <Drawer.Screen
              key={page.path}
              name={page.title}
              options={{
                header: props => (
                  <Component
                    navigation={props.scene.descriptor.navigation}
                    currentPage={props.scene.route}
                    logoURL={navItems[productMenu]?.logo_image_url}
                    bgColor={navItems[productMenu]?.colors.background_color}
                  />
                ),
                title: page.display_title,
              }}>
              {() => {
                return (
                  <Suspense fallback={<></>}>
                    <GenericPage
                      path={`${page.display_path}` + ''}
                      title={page.display_title}
                      colors={navItems[productMenu]?.colors}
                      productMenu={productMenu}
                    />
                  </Suspense>
                );
              }}
            </Drawer.Screen>
          );
        })}
    </Drawer.Navigator>
  );
};

const styles = StyleSheet.create({
  drawerNavigator: {
    ...Platform.select({
      web: {boxShadow: '4px 0px 8px 0px rgba(0,0,0,0.7)', zIndex: 10},
      ios: {
        shadowColor: Colours.black,
        shadowOffset: {
          width: 3,
          height: 0,
        },
        shadowOpacity: 1,
        shadowRadius: 5.46,
      },
      android: {
        elevation: 9,
      },
    }),
  },
});

export default DrawerNavigator;
