import React, {useContext, useEffect, useRef} from 'react';
import {View, StyleSheet, Pressable, Image} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import {useRoute} from '@react-navigation/core';

import APIImage from '../global/Image';
import ProductLogoSlider from './ProductLogoSlider';
import BreadCrumbItem from '../../components/navigation/BreadCrumbItem';

import AppContext from '../../context/AppContext';
import {imageFromAPI, whiteOrBlackText} from '../../utils';
import {reset} from '../../utils/navigation';
import Layout from '../../assets/Layout';
import GlobalStyles from '../../assets/styles/';
import Colours from '../../assets/Colours';

import {NavProps} from '../../@types/navigation'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {LinkObj} from '../../@types/global'; // eslint-disable-line @typescript-eslint/no-unused-vars

// images
import Hamburger from '../../Images/Hamburger.png';
import Home from '../../Images/Home.png';
import HomeWhite from '../../Images/HomeWhite.png';
import Socials from './Socials';

const itemWidth = Layout.iconSizeExtraLarge;

const MainNav: React.FC<NavProps> = ({navigation, logoURL, bgColor}) => {
  const scrollViewRef = useRef<ScrollView>(null);
  const {name: routeName} = useRoute();
  const context = useContext(AppContext);
  const {navItems, setCurrentStack, currentStack} = context;
  const productMenu = Object.keys(navItems).find(n => n.includes(currentStack));
  const mainLogo = navItems.products.logo_image_url;

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      if (productMenu) {
        const index = navItems[productMenu].pages.findIndex(
          p => routeName.includes(p.display_title) || routeName === p.title,
        );

        if (index >= 0) {
          const scrollX =
            itemWidth * (index - 1) + Layout.spacerXXS * (index - 1);

          if (scrollViewRef?.current) {
            scrollViewRef.current?.scrollTo({
              x: scrollX < 0 ? 0 : scrollX,
              animated: false,
            });
          } else {
            setTimeout(
              () =>
                scrollViewRef.current?.scrollTo({
                  x: scrollX < 0 ? 0 : scrollX,
                  animated: true,
                }),
              1000,
            );
          }
        }
      }
    });

    return unsubscribe;
  }, [navItems, navigation, productMenu, routeName]);

  ///////////// Styles
  const background = bgColor || navItems.products.colors.background_color;
  const backgroundColor = {backgroundColor: background};

  return (
    <View style={[backgroundColor, GlobalStyles.paddingTop]}>
      <View style={GlobalStyles.row}>
        <Pressable
          style={styles.burgerMenuContainer}
          onPress={() => navigation.openDrawer()}>
          <Image source={Hamburger as any} style={styles.burgerMenu} />
        </Pressable>
        <View style={styles.centeredLogo}>
          <View style={styles.icon}>
            <APIImage
              resizeMode={'cover'}
              imageURL={imageFromAPI(logoURL || mainLogo, {
                width: Layout.iconSizeMediumLarge,
                height: Layout.iconSizeMediumLarge,
              })}
            />
          </View>
        </View>

        <View style={styles.spacer} />
        <View
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            height: 50,
            alignItems: 'center',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            flexWrap: 'wrap',
            maxWidth: 120,
            width: '100%',
          }}>
          <Socials size="small" />
        </View>
      </View>

      {routeName === 'Home' && (
        <ProductLogoSlider
          onPress={(page: LinkObj) => {
            setCurrentStack(page.display_path);
            navigation.navigate(page.title);
          }}
        />
      )}

      {productMenu && (
        <View
          style={[
            GlobalStyles.row,
            GlobalStyles.fullWidth,
            GlobalStyles.marginBottomMS,
          ]}>
          <Pressable
            onPress={() => {
              setCurrentStack(undefined);
              reset(navigation);
            }}
            style={[
              GlobalStyles.iconWidthMediumSmall,
              GlobalStyles.iconHeightSmall,
              GlobalStyles.justifyCenter,
              GlobalStyles.alignCenter,
              GlobalStyles.opaqueBGOneTenth,
              GlobalStyles.marginRightXXS,
            ]}>
            <View
              style={[
                GlobalStyles.iconHeightExtraSmall,
                GlobalStyles.iconWidthExtraSmall,
              ]}>
              <Image
                source={
                  whiteOrBlackText(bgColor || '') === Colours.black
                    ? (Home as any)
                    : (HomeWhite as any)
                }
                style={[GlobalStyles.fullHeight, GlobalStyles.fullWidth]}
              />
            </View>
          </Pressable>
          <ScrollView
            ref={scrollViewRef}
            horizontal
            style={GlobalStyles.width95pct}
            showsHorizontalScrollIndicator={false}>
            {navItems[productMenu].pages.map(page => {
              if (page?.title === 'BlogPost') return;
              const pages = navItems.products.pages.filter(a => !!a);
              const prod = pages.find(
                p => p.path === productMenu.split('secondary/')[1],
              );
              const navText =
                prod?.display_path === page.display_path
                  ? prod.title
                  : page.title;
              const currentRouteBasedOnDisplayName = pages.find(
                p => p?.display_title === routeName,
              );

              const currentRoute =
                routeName.includes(page.display_title) ||
                routeName === page.title ||
                currentRouteBasedOnDisplayName?.path === page.display_path;
              const width = {minWidth: itemWidth};

              return (
                <BreadCrumbItem
                  key={`subNavCrum${page.path}`}
                  text={page.display_title}
                  navText={navText}
                  link={page.page_type === 'link' ? page.display_path : ''}
                  navigation={navigation}
                  canNav={!currentRoute}
                  bgColor={bgColor || ''}
                  style={[
                    width,
                    GlobalStyles.iconHeightSmall,
                    currentRoute && GlobalStyles.opaqueBGOneHalf,
                  ]}
                />
              );
            })}
          </ScrollView>
        </View>
      )}
    </View>
  );
};

const iconSize = Layout.iconSizeMediumLarge;
const styles = StyleSheet.create({
  burgerMenuContainer: {
    height: 50,
    width: 50,
    marginLeft: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  burgerMenu: {
    height: '50%',
    width: '50%',
  },
  icon: {
    height: iconSize,
    width: Layout.iconSizeExtraLarge,
  },
  spacer: {
    height: iconSize,
    width: iconSize,
  },
  centeredLogo: {
    position: 'absolute',
    left: '50%',
    marginLeft: -(iconSize / 2),
    top: -Layout.spacerSM,
  },
});

export default MainNav;
