/**
 * AppNavigation wraps our app in a NavigationContainer, passing through the linking prop
 *
 * It renders our DrawerNaigator, which is responsible for rendering all screens in the app
 */

import React from 'react'
import {NavigationContainer} from '@react-navigation/native'

import DrawNavigator from './drawer/DrawerNavigator'
import {AppNavigationProps} from '../@types/navigation' // eslint-disable-line @typescript-eslint/no-unused-vars

const AppNavigation: React.FC<AppNavigationProps> = ({linking}) => {
  return (
      <NavigationContainer linking={linking}>
        <DrawNavigator />
      </NavigationContainer>
  )
}

export default AppNavigation
