import React from 'react';
import {
  View,
  StyleSheet,
  Image,
  Platform,
} from 'react-native';

import logoImage from '../Images/logo.png'

const platfromIsWeb = Platform.OS === 'web'

const Splashscreen = () => {
  return (
    <View style={styles.container}>
      <Image
        accessibilityLabel="Logo"
        resizeMode="contain"
        style={platfromIsWeb ? styles.webImage : styles.image}
        source={platfromIsWeb ? logoImage : require('../Images/logo.png')}
        />
    </View>
  );
};

export default Splashscreen;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: '#1F00D1',
    justifyContent: 'center',
    alignItems: 'center',
  },
  webImage: {
    height: 250,
    width: 250,
  },
  image: {
  },
});
