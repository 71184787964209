/**
 * Comoonent to show all balls from a single draw, including the booster.
 * Expects:
 * - balls
 */

import React from 'react'
import {StyleProp, View, ViewProps} from 'react-native' // eslint-disable-line @typescript-eslint/no-unused-vars

import LottoBall from './LottoBall'

import GlobalStyles from '../../assets/styles'

import {LottoRowProps} from '../../@types/components' // eslint-disable-line @typescript-eslint/no-unused-vars

const LottoRow: React.FC<LottoRowProps> = ({
  numbers,
  product,
  size,
  style,
}) => {
  // Move bonus number to end
  const ordered = numbers.sort((a, b) => (
    (a.bonus || a.order === 0) ? 1 : (b.bonus || b.order === 0) ? -1 : 0
  ))

  return (
    <View style={[
      GlobalStyles.row,
      // GlobalStyles.justifyEnd,
      style,
      ]}>
      {ordered.map((draw) => {
        const bonusNum = (draw.bonus || draw.order === 0)
        const bonusNumberStyle = bonusNum ? {flex: 1, alignItems: 'flex-end'} as StyleProp<ViewProps> : {}

        return (
          <View
            key={`${product}${draw.number}`}
            style={[
              size === 'Medium' ? GlobalStyles.paddingRight1pct : GlobalStyles.paddingRightXXS,
              bonusNumberStyle,
              bonusNum ? GlobalStyles.noPaddingRight : undefined,
            ]}>
            <LottoBall
              number={draw.number.toString()}
              size={size}
              product={product}
            />
          </View>
        )
      })}
    </View>
  )
}

export default LottoRow
