import Colours from '../Colours';
import {StringIndex} from '../../@types/global'; // eslint-disable-line @typescript-eslint/no-unused-vars

const colourMapping = {
  'irish-lotto-bet': {
    '1-47': Colours.irishLottoBall,
  },

  '49s': {
    '1': Colours.limeGreenBall,
    '2': Colours.redBall,
    '3': Colours.orangeBall,
    '4': Colours.yellowBall,
    '5': Colours.brownBall,
    '6': Colours.purpleBall,
    '7': Colours.royalBlueBall,
  },
  '39s': {
    '1-10': Colours.pantone.yellow,
    '11-20': Colours.pantone.lightBlue,
    '21-30': Colours.pantone.orange,
    '31-39': Colours.pantone.purple,
  },
  lotto365: {
    '1-12': '#FFf945',
    '13-24': '#00FFC3',
    '25-36': '#FFFFFF',
  },
  '15s': {
    '1-15': Colours.pantone.yellow,
  },
} as StringIndex;

export default colourMapping;
