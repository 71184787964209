// TODO: CLEANUP AND CONVERT TO .tsx

import React from 'react';
import PropTypes from 'prop-types';
import {
  Animated,
  I18nManager,
  PanResponder,
  Pressable,
  StyleSheet,
  View,
} from 'react-native';

import Badge from './Badge';

const useNativeDriver = false; // because of RN #13377

class Swiper extends React.Component {
  animatedViewRef = React.createRef();

  children = (() => React.Children.toArray(this.props.children))();
  count = this.props.actualAdLength;
  multiplier = 0.8;
  controlsEnabled = this.props.controlsEnabled;

  startAutoplay() {
    const {timeout} = this.props;
    this.stopAutoplay();
    if (timeout) {
      this.autoplay = setTimeout(
        this._autoplayTimeout,
        Math.abs(timeout) * 1000,
      );
    }
  }

  stopAutoplay() {
    this.autoplay && clearTimeout(this.autoplay);
  }

  goToNext() {
    this._goToNeighboring();
  }

  goToPrev() {
    this._goToNeighboring(true);
  }

  goTo(index = 0) {
    const delta = index - this.getActiveIndex();
    if (delta) {
      this._fixAndGo(delta);
    }
  }

  getActiveIndex() {
    return this.state.activeIndex;
  }

  // stop public methods

  _autoplayTimeout() {
    const {timeout} = this.props;
    this._goToNeighboring(timeout < 0);
  }

  _goToNeighboring(toPrev = false) {
    this._fixAndGo(toPrev ? -1 : 1);
  }

  constructor(props) {
    super(props);

    this._autoplayTimeout = this._autoplayTimeout.bind(this);
    this._onLayout = this._onLayout.bind(this);
    this._fixState = this._fixState.bind(this);

    this.goToPrev = this.goToPrev.bind(this);
    this.goToNext = this.goToNext.bind(this);
    this.goTo = this.goTo.bind(this);

    this.state = {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      activeIndex: props.from,
      pan: new Animated.ValueXY(),
    };

    this._animatedValueX = 0;
    this._animatedValueY = 0;

    this._panResponder = PanResponder.create(this._getPanResponderCallbacks());
  }

  componentDidMount() {
    this.state.pan.x.addListener(({value}) => (this._animatedValueX = value));
    this.state.pan.y.addListener(({value}) => (this._animatedValueY = value));
    this.startAutoplay();
  }

  componentWillUnmount() {
    this.stopAutoplay();
    this.state.pan.x.removeAllListeners();
    this.state.pan.y.removeAllListeners();
  }

  _getPanResponderCallbacks() {
    return {
      onPanResponderTerminationRequest: () => false,
      onMoveShouldSetResponderCapture: () => true,
      onMoveShouldSetPanResponderCapture: (e, gestureState) => {
        const {gesturesEnabled, vertical, minDistanceToCapture} = this.props;

        if (!gesturesEnabled()) {
          return false;
        }

        this.props.onAnimationStart &&
          this.props.onAnimationStart(this.getActiveIndex());

        const allow =
          Math.abs(vertical ? gestureState.dy : gestureState.dx) >
          minDistanceToCapture;

        if (allow) {
          this.stopAutoplay();
        }

        return allow;
      },
      onPanResponderGrant: () => this._fixState(),
      onPanResponderMove: Animated.event(
        [
          null,
          this.props.vertical ? {dy: this.state.pan.y} : {dx: this.state.pan.x},
        ],
        {useNativeDriver: false},
      ),
      onPanResponderRelease: (e, gesture) => {
        const {vertical, minDistanceForAction} = this.props;
        const {width, height} = this.state;

        this.startAutoplay();

        const correction = vertical
          ? gesture.moveY - gesture.y0
          : gesture.moveX - gesture.x0;

        if (
          Math.abs(correction) <
          (vertical ? height : width) * minDistanceForAction
        ) {
          this._spring({x: 0, y: 0});
        } else {
          this._changeIndex(
            correction > 0
              ? !vertical && I18nManager.isRTL
                ? 1
                : -1
              : !vertical && I18nManager.isRTL
              ? -1
              : 1,
          );
        }
      },
    };
  }

  _spring(toValue) {
    const {springConfig} = this.props;
    const {activeIndex} = this.state;
    Animated.spring(this.state.pan, {
      ...springConfig,
      toValue,
      useNativeDriver, // false, see top of file
    }).start(() => {
      if (activeIndex > this.count + 1) {
        this.state.pan.setValue({x: this.state.width * (this.count - 1), y: 0});
        this.setState({activeIndex: 2});
      } else if (activeIndex < 2) {
        this.state.pan.setValue({x: this.state.width * 1, y: 0});
        this.setState({activeIndex: this.count + 1});
      }
    });
  }

  _fixState() {
    const {vertical} = this.props;
    const {width, height, activeIndex} = this.state;
    this._animatedValueX =
      width * activeIndex * (I18nManager.isRTL ? 1 : -1) + width * 0.125;
    this._animatedValueY = vertical ? height * activeIndex * -1 : 0;
    this.state.pan.setOffset({
      x: this._animatedValueX,
      y: this._animatedValueY,
    });
    this.state.pan.setValue({x: 0, y: 0});
  }

  _fixAndGo(delta) {
    this._fixState();
    this.props.onAnimationStart &&
      this.props.onAnimationStart(this.getActiveIndex());
    this._changeIndex(delta);
  }

  _changeIndex(delta = 1) {
    const {width, activeIndex} = this.state;

    let toValue = {x: 0, y: 0};
    let skipChanges = !delta;
    let calcDelta = delta;

    if (skipChanges) {
      return this._spring(toValue);
    }

    this.stopAutoplay();

    let index = activeIndex + calcDelta;
    this.setState({activeIndex: index});

    toValue.x = width * (I18nManager.isRTL ? 1 : -1) * calcDelta;

    this._spring(toValue);
    this.startAutoplay();
    this.props.onIndexChanged && this.props.onIndexChanged(index);
  }

  _onLayout({
    nativeEvent: {
      layout: {x, y, width, height},
    },
  }) {
    this.setState({x, y, width: width * this.multiplier, height}, () =>
      this._fixState(),
    );
  }

  _badgeClicked(newIndex) {
    this._fixState();

    const {width, activeIndex} = this.state;
    const delta = newIndex - activeIndex;
    const toValue = {x: 0, y: 0};
    toValue.x = width * (I18nManager.isRTL ? 1 : -1) * delta;

    this.stopAutoplay();
    this.setState({activeIndex: newIndex});
    this._spring(toValue);
    this.startAutoplay();
  }

  render() {
    const {pan, x, y, width, height} = this.state;

    const {
      vertical,
      positionFixed,
      containerStyle,
      innerContainerStyle,
      swipeAreaStyle,
      slideWrapperStyle,
    } = this.props;

    return (
      <View
        style={StyleSheet.flatten([styles.root, containerStyle])}
        onLayout={this._onLayout}>
        <View
          style={StyleSheet.flatten([
            styles.container(
              positionFixed,
              x,
              y,
              width * (1 / this.multiplier),
              height,
            ),
            innerContainerStyle,
          ])}>
          <Animated.View
            ref={this.animatedViewRef}
            style={StyleSheet.flatten([
              styles.swipeArea(vertical, this.count, width, height),
              swipeAreaStyle,
              {
                transform: [{translateX: pan.x}, {translateY: pan.y}],
              },
            ])}
            {...this._panResponder.panHandlers}>
            {this.children.map((el, i) => {
              if (!width && !height) {
                return null;
              }

              return (
                <View
                  key={i}
                  style={StyleSheet.flatten([
                    {width, height},
                    slideWrapperStyle,
                  ])}>
                  {el}
                </View>
              );
            })}
          </Animated.View>

          {this.props.controlsEnabled && (
            <View
              style={StyleSheet.flatten([
                styles.dotsWrapper(this.state.width),
              ])}>
              {Array.from({length: this.count}, (v, i) => i).map(index => {
                const isActive = index + 2 === this.state.activeIndex;

                return (
                  <Pressable
                    key={`Badge${index}`}
                    onPress={() =>
                      isActive ? undefined : this._badgeClicked(index + 2)
                    }>
                    <Badge
                      containerStyle={StyleSheet.flatten([
                        styles.dotsItemContainer,
                      ])}
                      isActive={isActive}
                    />
                  </Pressable>
                );
              })}
            </View>
          )}
        </View>
      </View>
    );
  }
}

Swiper.propTypes = {
  vertical: PropTypes.bool,
  from: PropTypes.number,
  loop: PropTypes.bool,
  timeout: PropTypes.number,
  gesturesEnabled: PropTypes.func,
  springConfig: PropTypes.object,
  minDistanceToCapture: PropTypes.number, // inside ScrollView
  minDistanceForAction: PropTypes.number,

  onAnimationStart: PropTypes.func,
  onAnimationEnd: PropTypes.func,
  onIndexChanged: PropTypes.func,

  positionFixed: PropTypes.bool, // Fix safari vertical bounces
  containerStyle: PropTypes.shape({
    style: PropTypes.any,
  }),
  innerContainerStyle: PropTypes.shape({
    style: PropTypes.any,
  }),
  swipeAreaStyle: PropTypes.shape({
    style: PropTypes.any,
  }),
  slideWrapperStyle: PropTypes.shape({
    style: PropTypes.any,
  }),

  controlsEnabled: PropTypes.bool,
  // controlsProps: PropTypes.shape(DefaultControls.propTypes),
  Controls: PropTypes.func,

  theme: PropTypes.object,
};

Swiper.defaultProps = {
  vertical: false,
  from: 0,
  loop: false,
  timeout: 0,
  gesturesEnabled: () => true,
  minDistanceToCapture: 5,
  minDistanceForAction: 0.2,
  positionFixed: false,
  controlsEnabled: true,
};

const styles = {
  root: {
    flex: 1,
    backgroundColor: 'transparent',
  },
  // Fix web vertical scaling (like expo v33-34)
  container: (positionFixed, x, y, width, height) => ({
    backgroundColor: 'transparent',
    // Fix safari vertical bounces
    position: positionFixed ? 'fixed' : 'relative',
    overflow: 'hidden',
    top: positionFixed ? y : 0,
    left: positionFixed ? x : 0,
    width,
    height,
    justifyContent: 'space-between',
  }),
  swipeArea: (vertical, count, width, height) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: vertical ? width : width * count,
    height: vertical ? height * count : height,
    flexDirection: vertical ? 'column' : 'row',
  }),

  // Badges

  dotsWrapper: width => ({
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 1,
    minHeight: 1,
    width: 50,
    // backgroundColor: 'pink',
    position: 'absolute',
    bottom: 0,
    left: (width * (1 / 0.8)) / 2 - 50 / 2,
  }),
  dotsItemContainer: {
    margin: 3,
  },
};

export default Swiper;
