// Store information about the numbers products currently in the app

export const allProducts = [
  // Numbers
  {
    code: '49',
    path: '49s',
  },
  {
    code: 'IL',
    path: 'irish-lotto-bet',
  },
  {
    code: '39',
    path: '39s',
  },
  {
    code: '36',
    path: 'lotto365',
  },
  {
    code: '15',
    path: '15s',
  },
  // Racing
  {
    code: 'HR',
    path: 'virtual-horse-racing',
  },
  {
    code: 'DG',
    path: 'virtual-greyhound-racing',
  },
] as Array<{code: '49' | 'IL' | 'HR' | 'DG'; path: string}>;

export const numbersProducts = [
  {
    code: '49',
    path: '49s',
    minNum: 1,
    maxNum: 49,
    eventNumberMapping: {
      1: 'Lunchtime',
      2: 'Teatime',
    },
  },
  {
    code: 'IL',
    path: 'irish-lotto-bet',
    minNum: 1,
    maxNum: 47,
    eventNumberMapping: {
      1: 'Main',
      2: '2nd',
      3: '3rd',
    },
  },
  {
    code: '39',
    path: '39s',
    minNum: 1,
    maxNum: 39,
    eventNumberMapping: {
      1: '1st',
      2: '2nd',
      3: '3rd',
      4: '4th',
      5: '5th',
    },
  },
  {
    code: '36',
    path: 'lotto365',
    minNum: 1,
    maxNum: 36,
    eventNumberMapping: {
      1: '1st',
      2: '2nd',
      3: '3rd',
      4: '4th',
      5: '5th',
    },
  },
  {
    code: '15',
    path: '15s',
    minNum: 1,
    maxNum: 15,
    eventNumberMapping: {
      1: '1st',
      2: '2nd',
      3: '3rd',
      4: '4th',
      5: '5th',
    },
  },
] as Array<{
  code: '49' | 'IL' | '39' | '36' | '15';
  path: '49s' | 'irish-lotto-bet' | '39s' | 'lotto365' | '15s';
  minNum: number;
  maxNum: number;
  eventNumberMapping: {[key: number]: string};
}>;
