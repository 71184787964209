import React, {useEffect} from 'react';
import {NextToGoProps} from '../@types/components'; // eslint-disable-line
import {getOrderedNextToGo} from '../components/navigation/Utils';

const useNexToGo = () => {
  const [nextToGo, setNextToGo] = React.useState<Array<NextToGoProps>>([]);

  const getAndSetNextTogo = () => {
    getOrderedNextToGo().then(ordered => {
      setNextToGo(ordered);
    });
  };

  useEffect(() => {
    getAndSetNextTogo();
  }, []);

  return {
    nextToGo,
    getAndSetNextTogo,
  };
};

export default useNexToGo;
