/**
 * All font values
 */

export default {
  sizeTitle1: 55,
  sizeTitle2: 26,
  sizeTitle3: 22,
  sizeTitle4: 20,
  sizeTitle5: 18,
  sizeBody: 16,
  sizeCallout: 15,
  sizeSubhead: 14,
  sizeFootnote: 12,
  sizeCaption: 10,
  sizeMini: 8,

  family: 'OpenSans-Regular',
  familyLight: 'OpensSans-Light',
  familySemiBold: 'OpenSans-SemiBold',
  familyBold: 'OpenSans-Bold',
  familyItalic: 'OpenSans-Italic',
  Futura: 'Futura-Bold',
};
